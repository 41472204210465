import axios from "axios";
import urls from "@/shared/urls";

var baseUrl = urls.NotificationsBaseUrl;
var endpoint = "/usermessages";

export default {
  
  async get(data) {
    const result = await axios.get(baseUrl + endpoint, {
      headers: {
        // Authorization: `Bearer ${data.jwtToken}`
      }
    });

    return result.data;
  },

  async getUnreadCount() {
    const result = await axios.get(baseUrl + endpoint + "?unreadCountOnly=true", {
      headers: {
        // Authorization: `Bearer ${data.jwtToken}`
      }
    });

    return result.data;
  },

  async markAsRead(requestData) {

    const result = await axios.put(
      `${baseUrl + endpoint}/${requestData.id}`,
      requestData.customerData, {
        headers: {
          //
        }
      });

    return result.data;
  }
};
