/*jshint esversion: 6 */

import {
  showErrorsInConsole
} from '@/shared/constants';


export function handleRequestError(error) {

  if (showErrorsInConsole && showErrorsInConsole == "true") {
    console.log(error);
  }

  if (error.response && error.response.data && (error.response.data.detail || error.response.data.Detail)) {

    if (error.response.status == 400 && error.response.data.invalidParameters) {
      return error.response.data.invalidParameters[0].reason;
    }


    if (error.response.data.detail)
      return error.response.data.detail;

    return error.response.data.Detail;

  } else {
    if (error.response.data)
      return error.response.data;

    return "An unexpected error has occurred.";
  }
}

export function handleRequestErrorNew(error) {

  if (showErrorsInConsole && showErrorsInConsole == "true") {

    console.log(error);
  }

  if (error.response && error.response.data && (error.response.data.detail || error.response.data.Detail)) {

    if (error.response.status == 400 && error.response.data.invalidParameters) {
      return error.response.data.invalidParameters[0].reason;
    }

    let title = error.response.data.title;
    let detail = error.response.data.detail;

    if (!detail) {
      title = error.response.data.Title;
      detail = error.response.data.Detail;
    }

    return {
      title: title,
      detail: detail.substring(0, detail.length > 200 ? 200 : detail.length)
    };


  } else {


    if (error.response && error.response.data)
      return {
        title: error.response.data,
        detail: ""
      };

    return {
      title: "An unexpected error has occurred.",
      detail: ""
    };

  }

}
