import axios from "axios";
import _ from "lodash";
import urls from "@/shared/urls";


const namespaced = true;

const state = {
  notifications: [],
	totalNotifications: 0,
  selectedNotification: null,
  notificationEventTypes: [],
  consignees: [],
  selectedConsignee: null,

  newMessageCount: 0,
  messages: []
};

const mutations = {
  SET_NOTIFICATIONS_EVENTTYPES: (state, payload) => {
    state.notificationEventTypes = payload;
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
	SET_TOTAL_NOTIFICATIONS: (state, payload) => {
		state.totalNotifications = payload;
	},
  SET_SELECTED_NOTIFICATION: (state, payload) => {
    state.selectedNotification = payload;
  },
  ADD_NOTIFICATION: (state, payload) => {
    state.notifications.push(...payload);
  },
  UPDATE_NOTIFICATION: (state, payload) => {
    var index = _.findIndex(state.notifications, function (item) {
      return item.id == payload.id;
    });
    state.notifications.splice(index, 1, payload);
  },
  SET_CONSIGNEES: (state, payload) => {
    state.consignees = payload;
  },
  MUTATE_SET_CONSIGNEES: (state, payload) => {
    state.consignees = payload;
  },
  SET_SELECTED_CONSIGNEE: (state, payload) => {
    state.selectedConsignee = payload;
  },

  SET_NEW_MESSAGE_COUNT: (state, payload) => {
    state.newMessageCount = payload;
  },

  SET_MESSAGES: (state, payload) => {
    state.messages = payload;

  }
};
const actions = {
  async getAllNotificationEventTypes(context, data) {

    const notifications = await axios.get(urls.NotificationEventTypesUrl, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_NOTIFICATIONS_EVENTTYPES", notifications.data);
  },
  async getAllNotifications(context, data) {
		const { take, skip } = data;

    const notifications = await axios.get(`${urls.NotificationSubscriptionsUrl}?take=${take}&skip=${skip}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });

    if (!notifications.data)
      notifications.data = [];
    context.commit("SET_NOTIFICATIONS", notifications.data.items);
		context.commit("SET_TOTAL_NOTIFICATIONS", notifications.data.total);
  },
  async getNotification(context, data) {
    const notification = await axios.get(`${urls.NotificationSubscriptionsUrl}/${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_SELECTED_NOTIFICATION", notification.data);
    return notification.data;
  },
  async addNewNotification(context, requestData) {
    await axios.post(urls.NotificationSubscriptionsUrl, requestData.data, {
      headers: {

      }
    });
  },
  async updateNotification(context, requestData) {

    const notification = await axios.put(
      `${urls.NotificationSubscriptionsUrl}/${requestData.data.id}`,
      requestData.data, {
        headers: {

        }
      }
    );

    context.commit("UPDATE_NOTIFICATION", notification.data);
  },
  async deleteNotification(context, requestData) {
    await axios.delete(`${urls.NotificationSubscriptionsUrl}/${requestData.id}/${requestData.subscriptionRelationId}`, {
      headers: {

      }
    });
  },
  async setSelectedNotification(context, notification) {
    context.commit("SET_SELECTED_NOTIFICATION", notification);
  },

  async setSelectedConsignee(context, consignee) {
    context.commit("SET_SELECTED_CONSIGNEE", consignee);
  },

  async setNewMessageCount(context, payload) {

    context.commit("SET_NEW_MESSAGE_COUNT", payload);

  },

  async setMessages(context, payload) {

    context.commit("SET_MESSAGES", payload);

  },

  setConsignees({
    commit
  }, payload) {
    commit("MUTATE_SET_CONSIGNEES", payload);
  },

  async getConsignees(context, requestData) {

    var consignees = await axios.get(
      `${urls.IdentityServerUrl}/consignees`, {
        headers: {

        }
      }
    );

    if (!consignees.data || consignees.data == "")
      consignees.data = [];

    var consigneeData = [];

    consignees.data.forEach(consignee => {
      consigneeData.push({
        id: consignee.id,
        name: consignee.name.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        billTo: consignee.billTo,
        city: consignee.city,
        state: consignee.state,
        address: consignee.address
      });
    });
    context.commit("SET_CONSIGNEES", consigneeData);
    return true;
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
