<template id="tmplOidcCallbackError">
  <div id="divOidcCallbackError" class="app flex-row align-items-center">
    <div id="divlOidcCallbackErrorParent" class="container vld-parent">
      <b-row id="rowOidcCallbackError" class="justify-content-center">
        <b-col id="rowOidcCallbackError" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpOidcCallbackError">
            <b-card id="crdOidcCallbackError" no-body class="p-4">
              <b-card-body id="crdbdyOidcCallbackError">
                <div id="divTFLogo" class="body-logo-container">
                  <img id="imgTFLogo" src="/img/tripfocus.png" class="pb-4" alt="TripFocus" />
                </div>
                <b-row>
                  <b-col id="colAuthError" cols="12">
                    <h4 id="hdrAuthError" class="text-center pb-3">
                      An error occurred with Authentication.
                    </h4>

                    <p id="pAuthErrorTryAgain">Please click the button below to try again.</p>

                    <b-button
                      id="btnSignIn"
                      block
                      variant="outline-primary"
                      @click="navigateToLogin"
                      >Sign in</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "OidcCallbackError.vue",
  methods: {
    navigateToLogin() {
      this.$router.push("/");
    }
  }
};
</script>

