<template id="tmplOrderTemplateSlideOut">
  <section id="secTemplateslideOut" class="container" style="margin-top:70px">
    <b-row id="rowCloseOrdTempSlideOut" class="mb-2">
      <b-col id="colCloseOrdTempSlideOut">
        <b-button id="btnCloseOrdTempSlideOut" variant="link" @click="closeWindow()">
          <i id="itlCloseOrdTempSlideOut" class="fa fa-lg fa-window-close" aria-hidden="true"></i>
        </b-button>
      </b-col>
    </b-row>
    <b-row id="rowOrdTempSlideOutSearch" class="justify-content-end mb-4 header-row">
      <b-col id="colOrdTempSlideOutSearch" lg="4" md="4" sm="12">
        <b-row id="rowOrdTempSlideOutSearchControl">
          <b-input-group id="txtGrpOrdTempSlideOutSearch">
            <b-form-input
              id="txtOrdTempSlideOutSearh"
              type="text"
              v-model="searchTerm"
              @keyup.enter="getOrderTemplates()"
              placeholder="Search Templates"
            />
            <b-input-group-append id="txtGrpApdOrdTempSlideOutSearh">
              <b-button id="btnOrdTempSlideOutSearch" @click="getOrderTemplates()" variant="primary">
                <i id="itlOrdTempSlideOutSearch" class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
      </b-col>
    </b-row>
    <b-row id="rowOrdTempSlideOutDetailsHdrs" v-if="windowWidth > 768" class="header-row text-primary font-weight-bold">
      <b-col id="colOrdTempSlideOutNameHdr" lg="4" md="4" sm="6">Name</b-col>
      <!-- <b-col lg="2" md="2" sm="6">Template No.</b-col> -->
      <b-col id="colOrdTempSlideOutBillToHdr" lg="4" md="4" sm="6">Bill To</b-col>
      <b-col id="colOrdTempSlideOutDelSiteHdr" lg="4" md="4" sm="12">Delivery Site</b-col>
    </b-row>
    <b-row
      id="rowOrdTempslideOutNoTemplates"
      v-if="total == 0"
      class="justify-content-center mt-3 font-weight-bold"
    >There are no Order Templates to display.</b-row>

    <b-row id="rowOrdTempslideOutCard" class="card-row">
      <b-col id="colOrdTempSlideOutCard">
        <b-row id="rowOrdTempSlideOutTempID" v-for="(orderTemplate) in orderTemplates" :key="orderTemplate.id">
          <b-card
            id="crdOrdTempSlideOutDetails"
            @click="createOrderFromTemplate(orderTemplate)"
            class="card-style"
            style="width: 100%; cursor: pointer"
          >
            <b-row id="rowOrdTempSlideOutDetails" class="align-items-center">
              <b-col id="colOrdTempSlideOutTemplateName" :class="{'mt-2': windowWidth < 768}" lg="4" md="4" sm="6">
                <span id="spnOrdTempSlideOutTemplateName" v-if="windowWidth < 768" class="text-primary font-weight-bold">Name:</span>
                {{orderTemplate.templateName}}
              </b-col>
              <!-- <b-col :class="{'mt-2': windowWidth < 768}" lg="2" md="2" sm="6">
                <span v-if="windowWidth < 768" class="text-primary font-weight-bold">Template No.</span>
                {{index}}
              </b-col> -->
              <b-col id="colOrdTempSlideOutBillToName" :class="{'mt-2': windowWidth < 768}" lg="4" md="4" sm="6">
                <span id="spnOrdTempSlideOutBillToName" v-if="windowWidth < 768" class="text-primary font-weight-bold">Bill To</span>
                {{orderTemplate.billTo.name}}
              </b-col>

              <b-col id="colOrdTempSlideOutDelSite" :class="{'mt-2': windowWidth < 768}" lg="4" md="4" sm="12">
                <span id="spnOrdTempSlideOutDelSite"  v-if="windowWidth < 768" class="text-primary font-weight-bold">
                  Delivery Site:
                  <br id="brOrdTempSlideOutDelSite">
                </span>
                {{orderTemplate.details != null && orderTemplate.details[0] != null ? orderTemplate.details[0].deliverySite.name : ''}}
              </b-col>
            </b-row>
          </b-card>
        </b-row>
      </b-col>
    </b-row>
    <b-row id="rowPagination" v-if="total > 0" class="justify-content-center mt-3">
      <b-pagination
        id="pgCurrentPage"
        v-model="currentPage"
        :total-rows="total"
        :per-page="pageSize"
        aria-controls="my-table"
        align="center"
        @input="getOrderTemplates(false)"
      ></b-pagination>
    </b-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
  export default {
  Name: "OrderTemplaesSlideOut.vue",
  components: {},
  data() {
    return {
      isLoading: false,
      windowWidth: window.innerWidth,
      currentPage: 1
    };
  },
  async created() {
    this.isLoading = true;

    var requestData = {
      jwtToken: this.$store.state.user.jwtToken,
      currentPage: this.currentPage
    };

    await this.$store.dispatch(
      "orderTemplate/getAllOrderTemplates",
      requestData
    );
    this.isLoading = false;
  },
  methods: {
    async getOrderTemplates() {
      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          currentPage: this.currentPage,
          searchTerm: this.searchTerm
        };

        await this.$store.dispatch(
          "orderTemplate/getAllOrderTemplates",
          requestData
        );
      } catch (error) {
        
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },
    createOrderFromTemplate(orderTemplate) {
      this.$store.dispatch("newOrderTender/setOrderTenderData", orderTemplate);
      this.$emit("closePanel", {});
    },
    closeWindow() {
      this.$emit("closePanel", {});
    }
  },
  computed: {
    ...mapState("orderTemplate", ["orderTemplates", "total", "pageSize"]),
    searchTerm: {
      get() {
        return this.$store.state.orderTemplate.searchTerm;
      },
      set(value) {
        this.$store.dispatch("orderTemplate/setSearchTerm", value);
      }
    }
  }
};
</script>

<style scoped>
.header-row {
  margin: 0 25px 10px 25px;
}
.card-row {
  margin: 0 25px 0 25px;
}
</style>

