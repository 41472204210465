import axios from "axios";
import urls from "@/shared/urls";
// var qs = require('qs');
import {
    handleRequestError,
  } from "../shared/utils/response-error-handler";

var baseUrl = urls.AiAssistantUrl;

export default {
    async chat(data) {
        try {
            var response = await axios.post(
                `${baseUrl}`,
                data,
                {
                    headers: {
                    }
                }
            );

            return {
                data: response.data,
                success: true
            };
        } catch (error) {
            return {
                data: handleRequestError(error),
                success: false,
            };
        }
    }
}