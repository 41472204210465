<template id="tmplOidcCallback">
  <div id="divOidcCallback" class="app flex-row align-items-center">
    <div id="divOidcCallbackParent" class="container vld-parent">
      <b-row id="rowOidcCallback" class="justify-content-center">
        <b-col id="colOidcCallBack" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpOidcCallback">
            <b-card id="crdOidcCallback" no-body class="p-4">
              <b-card-body id="crdbdyOidcCallback">
                <div id="divTFLogo" class="body-logo-container">
                  <img src="/img/tripfocus.png" class="pb-4" alt="TripFocus" />
                </div>
                <b-row id="colSigningInHdr" >
                  <b-col id="colSigningInHdr" cols="12">
                    <h3 id="hdrSigningIn" class="text-center pb-3">
                      Signing you in to TripFocus
                    </h3>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OidcCallback.vue",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback", "removeOidcUser"])
  },
  created() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        if (redirectPath && redirectPath != "/") {
          this.$router.push(redirectPath);
        } else {
          if (this.loggedInUser.updateProfile) {
            this.$router.push({ name: "profile", query: { userMigrated: 1 } });
          } else {
            this.$router.push({ name: "dashboard" });
          }
        }
      })
      .catch(err => {
        this.$router.push({ path: "/", query: { err: err } });
      });
  },
  computed: {
    ...mapGetters("user", ["loggedInUser"])
  }
};
</script>

