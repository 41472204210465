import moment from "moment";

export function hasTwentyFourHourBuffer(value, vm) {
  let now = moment();
  if (value && vm.selectedDeliveryDate) {
    var shipmentStartDateTime;
    switch (value.toLowerCase()) {
      case "morning":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(6);
        break;
      case "afternoon":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(12);
        break;

      case "evening":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(18);
        break;
      case "custom":

        shipmentStartDateTime = moment(
          vm.selectedFromCustomDate + "T" + vm.selectedFromCustomTime
        );
        break;
      default:
        break;
    }
    var diff = shipmentStartDateTime.diff(now, "hours");
    if (diff < 24) {
      return false;
    }
  }
  return true;
}

export function hasSixHourBuffer(value, vm) {
  let now = moment();
  if (value && vm.selectedDeliveryDate) {
    var shipmentStartDateTime;
    switch (value.toLowerCase()) {
      case "morning":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(6);
        break;
      case "afternoon":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(12);
        break;

      case "evening":
        shipmentStartDateTime = moment(vm.selectedDeliveryDate).hours(18);
        break;
      case "custom":

        shipmentStartDateTime = moment(
          vm.selectedFromCustomDate + "T" + vm.selectedFromCustomTime
        );
        break;
      default:
        break;
    }
    var diff = shipmentStartDateTime.diff(now, "hours");
    if (diff < 6) {
      return false;
    }
  }
  return true;
}

export function hasCustomTimeBuffer(value, vm) {
  let now = moment();
  if (value && vm.selectedDeliveryDate) {
    var formattedDateTime = moment(
      vm.selectedDeliveryDate + "T" + value,
      "YYYY-MM-DDTHH:mm:ss"
    ).format();
    var shipmentStartDateTime = moment(formattedDateTime);

    var diff = shipmentStartDateTime.diff(now, "hours");
    if (diff < 24) {
      return false;
    }
  }
  return true;
}

export function checkToDateTimeNotBeforeFromDateTime(value, vm) {
  if (
    value &&
    vm.selectedToCustomDate &&
    vm.selectedFromCustomDate &&
    vm.selectedFromCustomTime
  ) {
    var formattedFromDateTime = moment(
      vm.selectedFromCustomDate + "T" + vm.selectedFromCustomTime
    );

    var formattedToDateTime = moment(vm.selectedToCustomDate + "T" + value);

    var diff = formattedFromDateTime.diff(formattedToDateTime, "minutes");
    if (diff < 0) {
      return false;
    }
  }
  return true;
}

export function checkWindowHourBuffer(value, vm) {
  let now = moment();
  if (value && vm.selectedDeliveryDate) {
    var formattedDateTime = moment(
      vm.selectedDeliveryDate + "T" + value,
      "YYYY-MM-DDTHH:mm:ss"
    ).format();
    var shipmentStartDateTime = moment(formattedDateTime);

    var diff = shipmentStartDateTime.diff(now, "hours");
    if (diff < 24) {
      return false;
    }
  }
  return true;
}

export function isDateValid(value) {
  return moment(value).isValid();
}

export function parseDateTime(value) {
  var dateTimeSplit = moment(value)
    .format("YYYY-MM-DDTHH:mm")
    .split("T");

  var date = dateTimeSplit[0];
  var time = dateTimeSplit[1];

  return {
    date,
    time
  };
}

export function hasTwentyFourHourBufferFromCreatedDate(createdDate, newDate) {
  // there are so many different date formats?!
  // newDate is in YYYY-MM-DD AM format and firefox/safari does not like it
  // new Date() to remove the Deprecation warning from moment for invalid ISO date format
  var momentCreatedDate = moment(new Date(createdDate));
  var momentNewDate = moment(new Date(newDate.replace(/-/g,'/')));
  var diff = momentNewDate.diff(momentCreatedDate, "minutes");
  return diff > 1440;
}
