const namespaced = true;
const getDefaultFilters = () => {
  return {
    selectedConsignees: [],
    selectedCities: [],
    selectedStates: [],
    activeOrdersOnly: false,
    includeBillToConsignees: false,
    includeAccountOfConsignees: false,
    includeSupplierOfConsignees: false,
  };
};

const state = {
  orderToZoom: null,
  isFilterApplied: false,
  filterObject: {
    ...getDefaultFilters()
  }
};


const mutations = {

  MUTATE_SET_ORDER_TO_ZOOM: (state, payload) => {
    state.orderToZoom = payload;
  },
  SET_FILTER_OBJECT: (state, payload) => {
    state.filterObject = payload
  },

  SET_IS_FILTER_APPLIED: (state, payload) => {
    state.isFilterApplied = payload
  }
};

const actions = {
  setOrderToZoom({
    commit
  }, payload) {
    commit("MUTATE_SET_ORDER_TO_ZOOM", payload);
  },

  setFilterObject(context, payload) {
    context.commit("SET_FILTER_OBJECT", payload)
  },

  setIsFilterApplied(context, payload) {
    context.commit("SET_IS_FILTER_APPLIED", payload);
  },
};

const getters = {

};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
