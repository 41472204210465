import axios from "axios";
import urls from "@/shared/urls";


var baseUrl = urls.ImagePortalUrl;
var endpoint = "/DocTypes";

export default {
  async get(customerId) {
    var params;

    if (customerId) {
      params = {
        'customerId': customerId
      }
    }

    const response = await axios.get(baseUrl + endpoint, {
      headers: {

      },
      params: params
    });

    return response;
  }
};
