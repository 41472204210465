import axios from "axios";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  customer: null
};

const mutations = {
  SET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  }
};

const actions = {
  async getCustomer(context, data) {
    const customer = await axios.get(`${urls.CustomersUrl}/${data.id}`, {
      headers: {
        // Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_CUSTOMER", customer.data);
    return customer.data;
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
