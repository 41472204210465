import axios from "axios";
import urls from "@/shared/urls";
var qs = require('qs');
import store from '@/store/store';
import {
  handleRequestError,
} from "../shared/utils/response-error-handler";


var baseUrl = urls.CompaniesBaseUrl;
var citiesEndpoint = "/cities";
var statesEndpoint = "/states";
var terminalsEndpoint = "/terminals";
var carriersEndpoint = "/carriers";
var consigneessEndpoint = "/consignees";
var subsidiariesEndpoint = "/subsidiaries";
const suppliersEndpoint = "/suppliers"
const shippersEndpoint = "/shippers";

export default {
  async getCities(searchTerm, skip, take) {

    const response = await axios.get(baseUrl + citiesEndpoint, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwtToken}`
      },
      params: {
        searchTerm: searchTerm,
        skip: skip,
        take: take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },

  async getTerminals(searchTerm, skip, take) {

    const response = await axios.get(baseUrl + terminalsEndpoint, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwtToken}`
      },
      params: {
        searchTerm: searchTerm,
        skip: skip,
        take: take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },

  async getSubsidiaries() {

    const response = await axios.get(baseUrl + subsidiariesEndpoint, {
      headers: {},
      params: {},
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },

  async getCarriers(searchTerm, skip, take) {

    const response = await axios.get(baseUrl + carriersEndpoint, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwtToken}`
      },
      params: {
        searchTerm: searchTerm,
        skip: skip,
        take: take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },

  async getStates() {

    const response = await axios.get(baseUrl + statesEndpoint, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwtToken}`
      }
    });

    return response;
  },

  async getConsignees(searchTerm, id, city, state, includeBillToConsignees = false, includeAccountOfConsignees = false, includeSupplierOfConsignees = false, withActiveOrdersOnly = false, skip = 0, take = 20) {

    try {
      var reqParams = {
        skip: skip,
        take: take,
        includeBillToConsignees: includeBillToConsignees,
        includeAccountOfConsignees: includeAccountOfConsignees,
        includeSupplierOfConsignees: includeSupplierOfConsignees,
        withActiveOrdersOnly: withActiveOrdersOnly
      };

      if (id && id.length) {
        reqParams['id'] = id;
      }

      if (searchTerm) {
        reqParams['searchTerm'] = searchTerm;
      }

      if (city && city.length > 0) {
        reqParams['city'] = city;
      }

      if (state && state.length > 0) {
        reqParams['state'] = state;
      }

      const response = await axios.get(baseUrl + consigneessEndpoint, {
        headers: {
          Authorization: `Bearer ${store.state.user.jwtToken}`,
          transclude: "ActiveOrders"
        },
        params: reqParams,
        paramsSerializer: function (params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          });
        }
      });
      return {
        data: response.data,
        success: true,
      };
    } catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }

  },

  async getSuppliers(requestData) {
    let {
      consignee,
      searchTerm,
      id,
      city,
      state,
      skip = 0,
      take = 20,
      divisionToFilter
    } = requestData;

    try {
      const response = await axios.get(baseUrl + suppliersEndpoint, {
        headers: {
          Authorization: `Bearer ${store.state.user.jwtToken}`
        },
        params: {
          consignee: consignee,
          searchTerm: searchTerm,
          id: id,
          city: city,
          state: state,
          skip: skip,
          take: take,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          });
        }
      });

      let filteredSuppliers = [];

      if(divisionToFilter && response.data.results){
        filteredSuppliers = response.data.results
          .filter(a => a.division === divisionToFilter);
      }

      return {
        data: filteredSuppliers,
        success: true,
      };
    } catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
  },

	async getShippers(requestData){
		let {
      consignee,
      searchTerm,
      id,
      city,
      state,
      skip = 0,
      take = 20,
      divisionToFilter
    } = requestData;

    try {
      const response = await axios.get(baseUrl + shippersEndpoint, {
        headers: {
          Authorization: `Bearer ${store.state.user.jwtToken}`
        },
        params: {
          consignee: consignee,
          searchTerm: searchTerm,
          id: id,
          city: city,
          state: state,
          skip: skip,
          take: take,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          });
        }
      });

      let filteredSuppliers = [];

      if(divisionToFilter && response.data.results){
        filteredSuppliers = response.data.results
          .filter(a => a.division === divisionToFilter);
      }

      return {
        data: filteredSuppliers,
        success: true,
      };
    } catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	}
};
