
import axios from "axios";
import urls from "@/shared/urls";
import {
  handleRequestError,
} from "../shared/utils/response-error-handler";
var qs = require("qs");

var baseUrl = urls.OrdersV2Url;
var endpoint = "/export";
export default {
  
  async get(data) {
      console.log('data:', data);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`,
        },
      };
    
      const response = await axios.post(`${baseUrl}${endpoint}`, data.selectedOrders, config);

      return response;
    }
    catch (error) {
          return {
        data: handleRequestError(error),
        success: false,
      };

    }
  }
};