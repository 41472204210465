import axios from "axios";
import urls from "@/shared/urls";


export default {
  async get(data, bringDeleted = false) {
    const users = await axios.get(`${urls.UsersUrl}`, {
      headers: {
        //Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data ? data.id : "",
				includeDeletedUsers: bringDeleted
      }
    });

    return users;
  },
  async getUser(requestData) {
    const getUserResult = await axios.get(`${urls.UsersUrl}/${requestData.userId}`, {
      headers: {

      }
    });
    return getUserResult;
  },

  async create(requestData) {
    const user = await axios.post(
      `${urls.UsersUrl}`,
      requestData.userData, {
        headers: {

        }
      }
    );

    return user;

  },
  async updateUser(requestData) {

    const user = await axios.put(
      `${urls.UsersUrl}/${requestData.userId}`,

      requestData.userData, {
        headers: {

        }
      }
    );

    return user;
  },
  async delete(requestData) {

    const user = await axios.delete(
      `${urls.UsersUrl}/${requestData.userId}`, {
        headers: {

        }
      }
    );

    return user;
  },

  async getUserPermissions(data) { //todo move to separate repo

    const customer = await axios.get(
      `${urls.PermissionsUrl}`, {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        },
        params: {
          userId: data.userId
        }
      }
    );

    return customer;
  },
  async updateUserPermissions(requestData) {

    const user = await axios.put(
      `${urls.PermissionsUrl}/${requestData.userId}`,

      requestData.data, {
        headers: {

        }
      }
    );
    return user;
  },
  async getAllowedUserPermissions(data) {

    const customer = await axios.get(
      `${urls.UsersUrl}/permissions`, {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        },
        params: {
          customerId: data.id
        }
      }
    );

    return customer;
  }
};
