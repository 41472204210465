<template>
  <div class="app" id="mainDefaultContainer">
    <AppHeader fixed id="appHeader">
      <SidebarToggler class="d-print-none d-lg-none" display="md" mobile id="sidebarToggler"/>
      <router-link class="navbar-brand" to="/dashboard" id="dashboardNavLink">
        <span class="navbar-brand-full" id="dashboardBrandSpan">
          <img :src="initialLogoUrl" :alt="logoAlt" :title="logoAlt" id="dashboardBrandImg"/>
        </span>
        <span class="navbar-brand-minimized" id="dashboardBrandNavMin">
          <img src="/img/tripfocus_min.png" id="dashboardBrandImgMin"/>
        </span>
      </router-link>
      <SidebarToggler class="d-md-down-none mr-auto" display="lg" id="sideBarToggler"/>
      <b-navbar-nav class="ml-auto" id="bNavBarNav">
        <b-nav-item class="d-md-down-none" id="bNavBarItem">
          <DefaultHeaderDropdownMssgs v-if="oidcUser" id="defaultHeaderDropdownMssgs"/>
        </b-nav-item>
        <DefaultHeaderDropdownAccnt id="defaultHeaderDropdownAccnt"/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body" id="defaultContainerBody">
      <AppSidebar class="d-print-none" fixed id="appSidebar">
        <SidebarHeader v-if="oidcUser" id="appSidebarHeader">
          <span class="text-left" id="userDisplayName">Hello {{ loggedInUser.displayName }}!</span>
        </SidebarHeader>
        <SidebarForm id="appSidebarForm"/>
        <SidebarNav
          id="appSidebarNav"
          :navItems="navItems"
          @mousedown.native="routeMousedown($event.target.href)"
        ></SidebarNav>
        <SidebarFooter id="appSidebarFooter"/>
        <SidebarMinimizer id="appSidebarMinimizer"/>
      </AppSidebar>
      <main class="main" id="breadCrumbsContainer">
        <!-- <Breadcrumb :list="list" /> -->
        <div
          :class="{
            'container-fluid': windowWidth >= 768,
            container: windowWidth < 768
          }"
          id="breadCrumbsDiv"
        >
          <div
            class="alert alert-danger text-center"
            v-if="showEnvironmentBanner == 'true'"
            id="showEnvironmentBannerDiv"
          >
            <span class="font-weight-bold" id="environmentMessageText">You are currently in our {{ instance }} Environment.</span
            >
          </div>
          <div class="alert alert-danger text-center" v-if="isBrowserSupported" id="supportedBrowserDiv">
            This browser is not supported.  Please <span id="notSupportedBrowserHelpSpan"><router-link id="helpRouterLink" to="/help"> 'Click Here' </router-link></span>  to see supported browsers
          </div>

          <transition name="fade" mode="out-in" id="routerViewTransition">
            <router-view id="defaultRouterView"></router-view>
          </transition>
        </div>
      </main>
    </div>
    <TheFooter id="footer">
      <!--footer-->
      <div class="col-md-6  text-center text-md-left" id="poweredByDiv">
        <span class="pr-2" style="color:#3268a0" id="poweredBySpan">Powered by</span>
        <img
          src="/img/Tripfocus-Logo-sm.png"
          alt="Powered by TripFocus"
          style="max-height:40px;"
          id="tripFocusLogoSm"
        />
      </div>

      <div class="col-md-6  text-center text-md-right" id="copyright">
        <small id="copyrightSmall">
          <span class="text-sm-right text-xs-center" id="copyrightSpanText">
            Copyright &copy; 2019-{{ new Date().getFullYear() }} TripFocus All
            rights reserved.
            <span class="d-sm-none mb-4" id="copyrightWarning"></span>
            Unauthorized duplication prohibited.
          </span>
        </small>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import DefaultHeaderDropdownNotif from "./DefaultHeaderDropdownNotif";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import DefaultHeaderDropdownMssgs from "./DefaultHeaderDropdownMssgs";
import DefaultHeaderDropdownTasks from "./DefaultHeaderDropdownTasks";

import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";

import { mapGetters } from "vuex";
import { instance } from "@/shared/instance";
import { showEnvironmentBanner } from "@/shared/feature-flags";

import {
  canViewOrders,
  canCreateAdHocOrders,
  canViewCustomers,
  canViewCustomerTemplates,
  canViewUsers,
  isExternalUser,
  canViewAdminMessages
} from "@/shared/utils/permissions";

import mixin from '@/shared/mixin';

export default {
  name: "DefaultContainer",

  mixins: [mixin],

  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultHeaderDropdown,
    DefaultHeaderDropdownMssgs,
    DefaultHeaderDropdownNotif,
    DefaultHeaderDropdownTasks,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },

  data() {
    return {
      /**@type {Number} - With of the current window.*/
      windowWidth: window.innerWidth,      
      /**@type {Boolean} - Checks if the current browser is supported by the app.*/
      isBrowserSupported: false,
      /**@type {String} - Checks the instance in which the app is deployed (DEV, QA, UAT, PROD) */
      instance,            
      /**@type {Boolean} - Checks if we should show the environment banner (should be true in all but PROD) */
      showEnvironmentBanner
    };
  },

  methods: {
    browserDetect(){      
      // Get the user-agent string 
      let userAgentString = navigator.userAgent; 
    
      // Detect Internet Explorer 
      let IExplorerAgent =  
      userAgentString.indexOf("MSIE") > -1 ||  
      userAgentString.indexOf("rv:") > -1; 
      
      this.isBrowserSupported = IExplorerAgent;
    },

    routeMousedown(targetUrl) {
      // this mousedown fires before new view is loaded so this.$router should hold the original route -- while mouse click fires after view loaded
      // dispatch the store function only if target url and route.path match (same route clicked)
      if (
        targetUrl &&
        targetUrl.endsWith(this.$route.path) &&
        this.$route.meta.stateModule
      ) {
        this.$store.dispatch(`${this.$route.meta.stateModule}/resetReload`);
      }
      // if there is no state module, have to call manually here
    }
  },

  computed: {
    ...mapGetters("user", ["loggedInUser"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("oidcStore", ["oidcAccessToken"]),

    name() {
      return this.$route.name;
    },

    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    },

    baseUrl() {
      var getUrl = window.location;
      return getUrl.protocol + "//" + getUrl.host + "/"; //+ getUrl.pathname.split('/')[1];
    },

    logoAlt() {
      //todo: expand this to be more dynamic, for now we only have kag and kagl
      if (this.baseUrl.indexOf("kagl.") > 0) {
        return "KAG Logistics";
      }

      return "Kenan Advantage Group";
    },

    initialLogoUrl() {
      //todo: expand this to be more dynamic, for now we only have kag and kagl
      if (this.baseUrl.indexOf("kagl.") > 0) {
        return "/img/company-logos/kag-logistics.png";
      }

      return "/img/company-logos/kag.png";
    },

    navItems() {
      const items = [];
      if (canViewOrders(this.$store)) {
        items.push({
          name: "Orders",
          url: "/order-tenders",
          icon: "fas fa-layer-group"
        });
      }
      if (canCreateAdHocOrders(this.$store) || canViewCustomerTemplates(this.$store) ) {
        items.push({
          name: "Order Templates",
          url: "/order-templates",
          icon: "icon-layers"
        });
      }

      const adminItems = [];
      if (canViewCustomers(this.$store)) {
        adminItems.push({
          name: "Customers",
          url: "/internal-admin/customers",
          icon: "icon-organization"
        });
      }

      // admin stuff.
      if (canViewUsers(this.$store)) {
        if (isExternalUser) {
          items.push({
            name: "Users",
            url: "/admin/users",
            icon: "icon-people"
          });
        } else {
          adminItems.push({
            name: "Users",
            url: "/admin/users",
            icon: "icon-people"
          });
        }
      }

      if (canViewAdminMessages(this.$store)) {
        adminItems.push({
          name: "Messages",
          url: "/admin/messages",
          icon: "icon-envelope"
        });
      }

      if (adminItems.length > 0) {
        items.push({
          name: "Admin",
          icon: "icon-lock",
          children: adminItems
        });
      }

      if (this.oidcUser) {
        items.push({
          name: "Notifications",
          url: "/notifications",
          icon: "icon-envelope"
        });
      }

      items.push({
        name: "Help",
        url: "/help",
        icon: "fas fa-question-circle"
      });

      return items;
    }
  },

  mounted() {    
    this.browserDetect();

    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });

    if (this.consoleLogPermitted())
      console.log(this.oidcAccessToken);
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
