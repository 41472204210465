import axios from "axios";
import urls from "@/shared/urls";


var baseUrl = urls.RelationshipsBaseUrl;
var endpoint = "/RelationshipFiltersPreview";

export default {
  async post(requestData) {
    const user = await axios.post(baseUrl + endpoint,
      requestData.postData, {
        headers: {

        }
      }
    );

    return user;

  }
};
