import axios from "axios";
import urls from "@/shared/urls";


export default {

  async getById(userId) {
    const getUserResult = await axios.get(`${urls.ProfileUrl}/${userId}`);
    return getUserResult;
  },

  async update(userId, userData) {

    const user = await axios.put(
      `${urls.ProfileUrl}/${userId}`, userData
    );

    return user;
  }
};
