var KagInternalCustomerId = "#{CustomerPortal.KagInternalCustomerId}";
var jwtDecode = require("jwt-decode");


if (~encodeURIComponent(KagInternalCustomerId).indexOf("%23%7B"))
  KagInternalCustomerId = "438C8979-19EC-E911-90F9-0025B5020AAE"; //dev internal customer id. Set this here for local dev. Otherwise should be replaced by Octopus variable.

// AI
function allowsAiAccess(store) {
  return hasPermission(store, "AllowTripFocusAssistant", "True");
}

// Orders
function canViewOrders(store) {
  return hasPermission(store, "LoadTenderPermissions", "View");
}

function canCreateAdHocOrders(store) {
  return hasPermission(store, "LoadTenderPermissions", "CreateAdHoc") && isExternalUser(store) //&& store.state.user.userData;
}

function canCreateOrdersFromTemplatesOnly(store) {
  return hasPermission(store, "LoadTenderPermissions", "CreateFromTemplate") && !canCreateAdHocOrders(store) && isExternalUser(store) //&& store.state.user.userData;
}

function canUpdateOrders(store) {
  return hasPermission(store, "LoadTenderPermissions", "UpdateAdHoc") || hasPermission(store, "LoadTenderPermissions", "UpdateFromTemplate");
}

function canDeleteOrders(store) {
  return hasPermission(store, "LoadTenderPermissions", "Delete");
}

//Customers
function canViewCustomers(store) {
  return hasPermission(store, "CustomerPermissions", "View");
}

function canCreateCustomers(store) {
  return hasPermission(store, "CustomerPermissions", "Create");
}

function canUpdateCustomers(store) {
  return hasPermission(store, "CustomerPermissions", "Update");
}

function canDeleteCustomers(store) {
  return hasPermission(store, "CustomerPermissions", "Delete");
}

//Customers
function canViewAdminMessages(store) {
  return hasPermission(store, "MessagePermissions", "View");
}

function canCreateAdminMessages(store) {
  return hasPermission(store, "MessagePermissions", "Create");
}

function canUpdateAdminMessages(store) {
  return hasPermission(store, "MessagePermissions", "Update");
}

function canDeleteAdminMessages(store) {
  return hasPermission(store, "MessagePermissions", "Delete");
}


//Users
function canViewUsers(store) {
  return hasPermission(store, "UserManagementPermissions", "View");
}

function canCreateUsers(store) {
  return hasPermission(store, "UserManagementPermissions", "Create");
}

function canUpdateUsers(store) {
  return hasPermission(store, "UserManagementPermissions", "Update");
}

function canDeleteUsers(store) {
  return hasPermission(store, "UserManagementPermissions", "Delete");
}

//IMAGES
function canViewImages(store) {
  return hasPermission(store, "ImagePermissions", "View");
}

function canViewOrderMaps(store) {
  return hasPermission(store, "OrderMapsPermissions", "View");
}
//Templates
function canViewCustomerTemplates(store){
  return hasPermission(store, "CustomerTemplatePermissions", "View");
}
function canModifyCustomerTemplates(store) {
  return canCreateCustomerTemplates(store)
    && canEditCustomerTemplates(store)
    && canDeleteCustomerTemplates(store);
}
function canCreateCustomerTemplates(store) {
  return hasPermission(store, "CustomerTemplatePermissions", "Create");
}
function canEditCustomerTemplates(store){
  return hasPermission(store, "CustomerTemplatePermissions", "Update");
}
function canDeleteCustomerTemplates(store){
  return hasPermission(store, "CustomerTemplatePermissions", "Delete");
}

function isExternalUser(store) {
  var decoded = getDecodedToken(store);
  var isExternal = decoded.customer_id.toUpperCase() != KagInternalCustomerId.toUpperCase();
  return isExternal;
}

// check the store user for the specific token and the view/update/create permission
function hasPermission(store, token, permission) {
	var decoded = getDecodedToken(store);
  return decoded && (decoded[token] || '').includes(permission);
}

function getDecodedToken(store) {
  let token = "";

  if (store.state.oidcStore.access_token) {
    token = store.state.oidcStore.access_token; //store.state.user.jwtToken;

    return jwtDecode(token);
  }

  return null;
}

export {
  allowsAiAccess,
  canViewOrders,
  canCreateOrdersFromTemplatesOnly,
  canCreateAdHocOrders,
  canUpdateOrders,
  canDeleteOrders,
  canViewCustomers,
  canCreateCustomers,
  canUpdateCustomers,
  canDeleteCustomers,
  canViewUsers,
  canCreateUsers,
  canUpdateUsers,
  canDeleteUsers,
  canViewImages,
  canViewOrderMaps,
  isExternalUser,
  KagInternalCustomerId,
  canViewAdminMessages,
  canCreateAdminMessages,
  canUpdateAdminMessages,
  canDeleteAdminMessages,
  canViewCustomerTemplates,
  canModifyCustomerTemplates,
  canCreateCustomerTemplates,
  canEditCustomerTemplates,
  canDeleteCustomerTemplates
};
