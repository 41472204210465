import axios from 'axios';
import urls from '@/shared/urls';
var qs = require('qs');

const namespaced = true;

const getDefaultFilters = () => {
  return {
    orderImages: []
  };
};
const state = {
  ...getDefaultFilters(),
  orders: [],
  orderDetails: null
};

const mutations = {
  SET_ORDER_IMAGES: (state, payload) => {
    state.orderImages = payload;
  },
  SET_DOC_TYPE_SELECTION: (state, payload) => {
    state.orderImages.find(w => w.docType === payload.type).isSelected = payload.val;
  },
  SELECT_ALL_IMAGE(state) {
    var filtered = state.orderImages.filter(w => w.allowedToView && w.available);
    var allselected = filtered.every(w => w.isSelected);
    filtered.forEach(image => image.isSelected = !allselected);
  }
};

const actions = {
  async getAllOrderImageData(context, requestData) {

    var docTypes = await axios.get(`${urls.ImagePortalUrl}/DocTypes/${requestData.division}/${requestData.orderNumber}`, {
      headers: {
        //
      },
      params: {
        billTo: requestData.billTo
      }
    });

    var images = [];
    docTypes.data.docTypes.forEach(image => {
      if (image.allowedToView && image.available) {
        images.push({
          showViewLoader: false,
          showDownloadLoader: false,
          isSelected: false,

          ...image

        });
      }
    })

    context.commit('SET_ORDER_IMAGES', images);
  },
  async getOrderImage(context, requestData) {
    var image = await axios.get(`${urls.ImagePortalUrl}/image/getorderimages`, {
      //responseType: 'arraybuffer',
      headers: {
        //
      },
      params: {
        orderNumber: requestData.orderNumber,
        billTo: requestData.billTo,
        docType: requestData.docType,
        processInBackground: requestData.processInBackground,
        division: requestData.division
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    if (requestData.processInBackground)
      return image;

    return image.data;
  },
  async getSelectedOrderImages(context, requestData) {
    //this is called from the order details page where they are requesting certain documents from a single order. 

    var url = `${urls.ImagePortalUrl}/image/getorderimages?orderNumber=${requestData.orderNumber}&billTo=${requestData.billTo}&division=${requestData.division}`;

    requestData.docTypes.forEach(docType => {
      url = url + `&docType=${docType}`;
    });

    var strByteArr = await axios.get(url, {
      headers: {
        
      },
    });
    return strByteArr.data;
  },
  selectAllImages(context) {
    context.commit('SELECT_ALL_IMAGE');
  },
  setDocTypeSelection(context, data) {
    context.commit('SET_DOC_TYPE_SELECTION', data);
  }
};

const getters = {
  enableDownload: (state) => {
    return state.orderImages && state.orderImages.some(w => w.allowedToView && w.available && w.isSelected);
  }
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
