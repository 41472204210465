import axios from "axios";
import moment from "moment";

import urls from "@/shared/urls";

import {
  parseDateTime
} from "@/shared/utils/date-validations";

import { getDateInterval, formatShipmentStartAndEndDateTime } from "@/shared/utils/date-formatter";

const namespaced = true;
const getDefaultState = () => {
  return {
    billTos: [],
    consignees: [],
    commodities: [],
    shippers: [],
    suppliers: [],
    accountOfs: [],
    selectedBillTo: "",
    selectedConsignee: "",

    // selectedDeliveryDate: moment().add(1, "days").format("YYYY-MM-DD").toString(),
    selectedDeliveryDate: "",
    selectedDeliveryTime: "",
    selectedFromCustomDate: "",
    selectedFromCustomTime: "",
    selectedToCustomDate: "",
    selectedToCustomTime: "",
    selectedShipper: "",
    selectedSupplier: "",
    selectedAccountOf: "",
    selectedCommodity: "",
    selectedSplashCommodity: "",
    commodityOne: "",
    commodityTwo: "",
    selectedQuantity: "",
    selectedProducts: [],
    poNumber: "",
    notes: "",
    saveTemplate: false,
    saveTemplatePoNumber: false,
    templateName: "",
    templateId: "",
    shipmentId: "",
    division: "",
    createdDate: "",
    selectedAmountUnitOfMeasure: "",
    selectedLoadEarliestDate: "",
    selectedLoadEarliestTime: "",
    selectedLoadLatestDate: "",
    selectedLoadLatestTime: "",
    loadWindow: "",
    selectedDeliveryEarliestDate: "",
    selectedDeliveryEarliestTime: "",
    selectedDeliveryLatestDate: "",
    selectedDeliveryLatestTime: "",
    deliveryWindow: "",
    isEditMode: false,
		thirdPartyPO: ""
  };
};

const state = {
  ...getDefaultState()
};

const mutations = {
  SET_BILL_TO: (state, payload) => {
    state.billTos = payload;
  },
  SET_CONSIGNEES: (state, payload) => {
    state.consignees = payload;
  },
  SET_COMMODITIES: (state, payload) => {
    state.commodities = payload;
  },
  SET_SHIPPERS: (state, payload) => {
    state.shippers = payload;
  },
  SET_SUPPLIERS: (state, payload) => {
    state.suppliers = payload;
  },
  SET_ACCOUNT_OFS: (state, payload) => {
    state.accountOfs = payload;
  },
  SET_SELECTED_CONSIGNEE: (state, payload) => {
    state.selectedConsignee = payload;
  },
  SET_SELECTED_DELIVERY_DATE: (state, payload) => {
    state.selectedDeliveryDate = payload;
  },
  SET_SELECTED_DELIVERY_TIME: (state, payload) => {
    state.selectedDeliveryTime = payload;
  },
  SET_SELECTED_FROM_CUSTOM_DATE: (state, payload) => {
    state.selectedFromCustomDate = payload;
  },
  SET_SELECTED_FROM_CUSTOM_TIME: (state, payload) => {
    state.selectedFromCustomTime = payload;
  },
  SET_SELECTED_TO_CUSTOM_DATE: (state, payload) => {
    state.selectedToCustomDate = payload;
  },
  SET_SELECTED_TO_CUSTOM_TIME: (state, payload) => {
    state.selectedToCustomTime = payload;
  },
  SET_SELECTED_SHIPPER: (state, payload) => {
    state.selectedShipper = payload;
  },
  SET_SELECTED_SUPPLIER: (state, payload) => {
    state.selectedSupplier = payload;
  },
  SET_SELECTED_ACCOUNT_OF: (state, payload) => {
    state.selectedAccountOf = payload;
  },
  SET_SELECTED_COMMODITY: (state, payload) => {
    state.selectedCommodity = payload;
  },
  SET_SELECTED_SPLASH_COMMODITY: (state, payload) => {
    state.selectedSplashCommodity = payload;
  },
  SET_COMMODITY_ONE: (state, payload) => {
    state.commodityOne = payload;
  },
  SET_COMMODITY_TWO: (state, payload) => {
    state.commodityTwo = payload;
  },
  SET_SELECTED_QUANTITY: (state, payload) => {
    state.selectedQuantity = payload;
  },
  SET_SELECTED_AMOUNTUNITOFMEASURE: (state, payload) => {
    state.selectedAmountUnitOfMeasure = payload;
    state.amountUnitOfMeasure=payload;
  },
  ADD_PRODUCT: (state, payload) => {
    if (payload.productIndex != null) {
      state.selectedProducts[payload.productIndex] = payload.product;
    } else {
      state.selectedProducts.push(payload.product);
    }
  },
  DELETE_PRODUCT: (state, payload) => {
    state.selectedProducts.splice(payload, 1);
  },
  SET_PO_NUMBER: (state, payload) => {
    state.poNumber = payload;
  },
  SET_NOTES: (state, payload) => {
    state.notes = payload;
  },
  SET_SAVE_TEMPLATE: (state, payload) => {
    state.saveTemplate = payload;
  },
  SET_SAVE_TEMPLATE_PO_NUMBER: (state, payload) => {
    state.saveTemplatePoNumber = payload;
  },
  SET_TEMPLATE_NAME: (state, payload) => {
    state.templateName = payload;
  },
  CLEAR_PRODUCT_DETAILS: state => {
    state.selectedShipper = "";
    state.selectedSupplier = "";
    state.selectedAccountOf = "";
    state.selectedCommodity = "";
    state.selectedQuantity = "";
    state.selectedSplashCommodity = "";
    state.commodityOne = "";
    state.commodityTwo = "";
    state.isEditMode = false;
  },
  CLEAR_SELECTED_PRODUCTS: state => {
    state.selectedProducts = [];
  },
  CLEAR_ORDER_TENDER_DATA: state => {
    state.shippers = [];
    state.suppliers = [];
    state.accountOfs = [];
    state.selectedConsignee = "";
    // state.selectedDeliveryDate = moment()
    //   .add(1, "days")
    //   .format("YYYY-MM-DD");
    state.selectedDeliveryDate = '';
    state.selectedDeliveryTime = "";
    state.selectedFromCustomDate = "";
    state.selectedFromCustomTime = "";
    state.selectedToCustomTime = "";
    state.selectedFromCustomDate = "";
    state.selectedShipper = "";
    state.selectedSupplier = "";
    state.selectedAccountOf = "";
    state.selectedCommodity = "";
    state.selectedQuantity = "";
    state.selectedAmountUnitOfMeasure = "";
    state.selectedProducts = [];
    state.poNumber = "";
    state.notes = "";
    state.saveTemplate = false;
    state.templateId = "";
    state.templateName = "";
    state.saveTemplatePoNumber = false;
    state.createdDate = "";
    state.amountUnitOfMeasure = "";
		state.thirdPartyPO = "";
  },
  SET_ORDER_TENDER_DATA: (state, payload) => {
		state.thirdPartyPO = payload.thirdPartyPO != null ? payload.thirdPartyPO : "";
    state.shippers = [];
    state.suppliers = [];
    state.accountOfs = [];
    state.selectedBillTo = payload.billTo;
    state.selectedDeliveryDate = payload.shipmentStartDateTime ?
      moment(payload.shipmentStartDateTime).format("YYYY-MM-DD") :
      moment()
      .add(1, "days")
      .format("YYYY-MM-DD");

    state.selectedDeliveryTime =
      payload.deliveryTimeWindow != null ?
      payload.deliveryTimeWindow.toLowerCase() :
      null;

    state.selectedFromCustomDate =
      payload.deliveryTimeWindow != null &&
      payload.deliveryTimeWindow.toLowerCase() == "custom" ?
      parseDateTime(payload.shipmentStartDateTime).date :
      "";

    state.selectedFromCustomTime =
      payload.deliveryTimeWindow != null &&
      payload.deliveryTimeWindow.toLowerCase() == "custom" ?
      parseDateTime(payload.shipmentStartDateTime).time :
      "";

    state.selectedToCustomDate =
      payload.deliveryTimeWindow != null &&
      payload.deliveryTimeWindow.toLowerCase() == "custom" ?
      parseDateTime(payload.shipmentEndDateTime).date :
      "";
    state.selectedToCustomTime =
      payload.deliveryTimeWindow != null &&
      payload.deliveryTimeWindow.toLowerCase() == "custom" ?
      parseDateTime(payload.shipmentEndDateTime).time :
      "";
    state.selectedProducts = payload.details;
    state.poNumber = payload.po;
    state.notes = payload.notes;
    state.shipmentId = payload.customId ? payload.customId : '';
    state.division = payload.division ? payload.division : '';
    state.templateId = payload.templateId;
    //this is not really utc
    state.createdDate = payload.createdDateTimeUtc;
    state.amountUnitOfMeasure = payload.selectedAmountUnitOfMeasure;
    //state.amountUnitOfMeasure = payload.selectedAmountUnitOfMeasure?? payload.amountUnitOfMeasure;
  },
  CLEAR_COMMODITIES: (state) => {
    state.commodities = [];
  },
  SET_SELECTED_LOAD_EARLIEST_DATE: (state, payload) => {
    state.selectedLoadEarliestDate = payload !== "" ? payload : "1950-01-01";
  },
  SET_SELECTED_LOAD_EARLIEST_TIME: (state, payload) => {
    state.selectedLoadEarliestTime = payload !== "" ? payload : "00:00";
  },
  SET_SELECTED_LOAD_LATEST_DATE: (state, payload) => {
    state.selectedLoadLatestDate = payload !== "" ? payload : "2049-12-31";
  },
  SET_SELECTED_LOAD_LATEST_TIME: (state, payload) => {
    state.selectedLoadLatestTime = payload !== "" ? payload : "23:59";
  },
  SET_LOAD_WINDOW: (state) => {
    if (state.selectedLoadEarliestDate !== "" 
        && state.selectedLoadEarliestTime !== ""
        && state.selectedLoadLatestDate !== ""
        && state.selectedLoadLatestTime)
    {
      state.loadWindow = getDateInterval(
        state.selectedLoadEarliestDate,
        state.selectedLoadEarliestTime,
        state.selectedLoadLatestDate,
        state.selectedLoadLatestTime
      )
    }
  },
  SET_SELECTED_DELIVERY_EARLIEST_DATE: (state, payload) => {
    state.selectedDeliveryEarliestDate = payload;
  },
  SET_SELECTED_DELIVERY_EARLIEST_TIME: (state, payload) => {
    state.selectedDeliveryEarliestTime = payload;
  },
  SET_SELECTED_DELIVERY_LATEST_DATE: (state, payload) => {
    state.selectedDeliveryLatestDate = payload;
  },
  SET_SELECTED_DELIVERY_LATEST_TIME: (state, payload) => {
    state.selectedDeliveryLatestTime = payload;
  },
  SET_DELIVERY_WINDOW: (state) => {
    if (state.selectedDeliveryEarliestDate !== "" 
        && state.selectedDeliveryEarliestTime !== ""
        && state.selectedDeliveryLatestDate !== ""
        && state.selectedDeliveryLatestTime)
    {
      state.deliveryWindow = getDateInterval(
        state.selectedDeliveryEarliestDate,
        state.selectedDeliveryEarliestTime,
        state.selectedDeliveryLatestDate,
        state.selectedDeliveryLatestTime
      )
    }
  },
  SET_SHIPMENT_DATE_TIME_WINDOWS: (state) => {
    var shipmentDateTimes = formatShipmentStartAndEndDateTime(
        state.selectedDeliveryTime,
        state.selectedDeliveryDate,
        state.selectedFromCustomDate,
        state.selectedFromCustomTime,
        state.selectedToCustomDate,
        state.selectedToCustomTime
    )
    var shipmentStartDateTime = moment.utc(shipmentDateTimes.shipmentStartDateTime)
    var shipmentEndDateTime = moment.utc(shipmentDateTimes.shipmentEndDateTime)
    
    const DATE = 'YYYY-MM-DD';
    const TIME = 'HH:mm';
    
    state.selectedLoadEarliestDate = shipmentStartDateTime.format(DATE).toString();
    state.selectedLoadEarliestTime = shipmentStartDateTime.format(TIME).toString();
    state.selectedLoadLatestDate = shipmentEndDateTime.format(DATE).toString();
    state.selectedLoadLatestTime = shipmentEndDateTime.format(TIME).toString();
    
    state.selectedDeliveryEarliestDate = shipmentStartDateTime.format(DATE).toString();
    state.selectedDeliveryEarliestTime = shipmentStartDateTime.format(TIME).toString();
    state.selectedDeliveryLatestDate = shipmentEndDateTime.format(DATE).toString();
    state.selectedDeliveryLatestTime = shipmentEndDateTime.format(TIME).toString();
  },  
  SET_IS_EDIT_MODE: (state) => {
    state.isEditMode = true
  },
	SET_DIVISION: (state, division) => {
		state.division = division;
	},
	SET_3PO_NUMBER: (state, payload) => {
    state.thirdPartyPO = payload;
  },
	SET_TEMPLATE_ID: (state, payload) => {
		state.templateId = payload;
	}
};

const actions = {

  async getAllBillTos(context, jwtToken) {
    var billTos = await axios.get(`${urls.RelationshipsBaseUrl}/billtos`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    });
    context.commit("SET_BILL_TO", billTos.data.results);
    return true;
  },


  async getConsignees(context) {
    var consignees = await axios.get(
      `${urls.RelationshipsBaseUrl}/consignees`
    );

    if (!consignees.data) {
      return;
    }

    var consigneeData = [];

    consignees.data.results.forEach(consignee => {
      consigneeData.push({
        id: consignee.id,
        name: consignee.name.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        billTo: consignee.billTo,
        city: consignee.city,
        state: consignee.state,
        address: consignee.address,
				address2: consignee.address2,
        division: consignee.division,
				zipCode: consignee.zipCode
      });
    });

		let division =
			consigneeData.length > 0 ?
			consigneeData[0].division :
			"FDG";
    context.commit("SET_DIVISION", division);
    context.commit("SET_CONSIGNEES", consigneeData);

    return;
  },

  async getShippers(context, requestData) {
    var shippers = await axios.get(
      `${urls.RelationshipsBaseUrl}/Shippers`, {
        headers: {

        },
        params: {
          billTo: requestData.billTo,
          consignee: requestData.consignee,
          division: requestData.division
        }
      });

    var shipperData = [];
    shippers.data.results.forEach(shipper => {
      shipperData.push({
        id: shipper.id,
        name: shipper.name.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        city: shipper.city,
        state: shipper.state,
        address: shipper.address,
				zipCode: shipper.zipCode
      });
    });

    context.commit("SET_SHIPPERS", shipperData);
  },
  async getSuppliers(context, requestData) {
    var suppliers = await axios.get(
      `${urls.RelationshipsBaseUrl}/Suppliers`, {
        headers: {

        },
        params: {
          division: requestData.division,
          billTo: requestData.billTo,
          consignee: requestData.consignee,
          shipper: requestData.shipper
        }
      });

    var supplierData = [];
    suppliers.data.results.forEach(supplier => {
      supplierData.push({
        id: supplier.id,
        name: supplier.name.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        city: supplier.city,
        state: supplier.state,
        address: supplier.address
      });
    });

    context.commit("SET_SUPPLIERS", supplierData);
  },
  async getAccountOfs(context, requestData) {
    var accountOfs = await axios.get(
      `${urls.RelationshipsBaseUrl}/AccountOfs`, {
        headers: {

        },
        params: {
          division: requestData.division,
          billTo: requestData.billTo,
          consignee: requestData.consignee,
          shipper: requestData.shipper,
          supplier: requestData.supplier
        }
      });

    var accountOfData = [];
    if (accountOfs.data) {
      accountOfs.data.results.forEach(accountOf => {
        accountOfData.push({
          id: accountOf.id,
          name: accountOf.name.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' '),
        });
      });
    } else {
      accountOfData.push({
        id: 'UNKNOWN',
        name: 'Unknown'
      });
    }
    context.commit("SET_ACCOUNT_OFS", accountOfData);
  },
  async getCommodities(context, requestData) {

    var parameters = {
      billTo: requestData.billTo,
      consignee: requestData.consignee,
      division: requestData.division
    }

    if (requestData.isAdvancedView) {
      parameters["shipper"] = requestData.shipper;
      parameters["supplier"] = requestData.supplier;
      parameters["accountOf"] = requestData.accountOf;
    }

    var commodities = await axios.get(
      `${urls.RelationshipsBaseUrl}/commodities`, {
        headers: {

        },
        params: parameters
      });

    var commoditiesData = [];

    commodities.data.results.forEach(c => {
      commoditiesData.push({
        id: c.commodityCode,
        name: c.name.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
      });
    });
    context.commit("SET_COMMODITIES", commoditiesData);
    return true;
  },

  async submitOrderTender(context, requestData) {
    var tender = await axios.post(
      urls.LoadTendersUrl,
      requestData.orderTender, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }
    );

    return tender.data;
  },

  async updateOrderTender(context, requestData) {
    var updateTender = await axios.put(
      `${urls.LoadTendersUrl}/${requestData.uId}`,
      requestData.orderTender, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }
    );
		return updateTender.data;
  },
  async updateSPGOrderTender(context, requestData) {
    var tender = await axios.put(
			`${urls.LoadTendersUrl}/${requestData.uId}`,
       requestData.orderTender, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }
    )
		return tender;
  },
  setSelectedConsignee(context, siteId) {
    context.commit("SET_SELECTED_CONSIGNEE", siteId);
  },
  setSelectedDeliveryDate(context, date) {
    context.commit("SET_SELECTED_DELIVERY_DATE", date);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS")
  },
  setSelectedDeliveryTime(context, time) {
    context.commit("SET_SELECTED_DELIVERY_TIME", time);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS");
  },
  setSelectedFromCustomDate(context, date) {
    context.commit("SET_SELECTED_FROM_CUSTOM_DATE", date);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS");
  },
  setSelectedFromCustomTime(context, time) {
    context.commit("SET_SELECTED_FROM_CUSTOM_TIME", time);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS");
  },
  setSelectedToCustomDate(context, date) {
    context.commit("SET_SELECTED_TO_CUSTOM_DATE", date);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS");
  },
  setSelectedToCustomTime(context, time) {
    context.commit("SET_SELECTED_TO_CUSTOM_TIME", time);
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS");
  },
  setSelectedShipper(context, shipper) {
    context.commit("SET_SELECTED_SHIPPER", shipper);
  },
  setSelectedSupplier(context, supplier) {
    context.commit("SET_SELECTED_SUPPLIER", supplier);
  },
  setSelectedAccountOf(context, accountOf) {
    context.commit("SET_SELECTED_ACCOUNT_OF", accountOf);
  },
  setSelectedCommodity(context, commodity) {
    context.commit("SET_SELECTED_COMMODITY", commodity);
  },
  setSelectedSplashCommodity(context, commodity) {
    context.commit("SET_SELECTED_SPLASH_COMMODITY", commodity);
  },
  setCommodityOne(context, commodity) {
    context.commit("SET_COMMODITY_ONE", commodity);
  },
  setCommodityTwo(context, commodity) {
    context.commit("SET_COMMODITY_TWO", commodity);
  },
  setSelectedQuantity(context, commodity) {
    context.commit("SET_SELECTED_QUANTITY", commodity);
  },
  setSelectedAmountUnitOfMeasure(context, commodity) {
    context.commit("SET_SELECTED_AMOUNTUNITOFMEASURE", commodity);
    this.selectedAmountUnitOfMeasure = commodity;
    this.amountUnitOfMeasure = commodity;
  },
  setPoNumber(context, poNumber) {
    context.commit("SET_PO_NUMBER", poNumber);
  },
  setNotes(context, notes) {
    context.commit("SET_NOTES", notes);
  },
  setSaveTemplate(context, saveTemplate) {
    context.commit("SET_SAVE_TEMPLATE", saveTemplate);
  },
  setSaveTemplatePoNumber(context, savePoNumber) {
    context.commit("SET_SAVE_TEMPLATE_PO_NUMBER", savePoNumber);
  },
  setTemplateName(context, templateName) {
    context.commit("SET_TEMPLATE_NAME", templateName);
  },
  clearProductDetails(context) {
    context.commit("CLEAR_PRODUCT_DETAILS");
  },
  addProduct(context, objProduct) {
    context.commit("ADD_PRODUCT", objProduct);
  },
  deleteProduct(context, objProduct) {
    context.commit("DELETE_PRODUCT", objProduct);
  },
  clearCommodities(context) {
    context.commit("CLEAR_COMMODITIES");
  },
  clearSelectedProducts(context) {
    context.commit("CLEAR_SELECTED_PRODUCTS");
  },
  clearOrderTenderData(context) {
    context.commit("CLEAR_ORDER_TENDER_DATA");
  },
  setOrderTenderData(context, objOrderTender) {
    context.commit("SET_ORDER_TENDER_DATA", objOrderTender);
  },
  setSelectedLoadEarliestDate(context, date) {
    context.commit("SET_SELECTED_LOAD_EARLIEST_DATE", date);
    context.commit("SET_LOAD_WINDOW");
  },
  setSelectedLoadEarliestTime(context, time) {
    context.commit("SET_SELECTED_LOAD_EARLIEST_TIME", time);
    context.commit("SET_LOAD_WINDOW");
  },
  setSelectedLoadLatestDate(context, date) {
    context.commit("SET_SELECTED_LOAD_LATEST_DATE", date);
    context.commit("SET_LOAD_WINDOW");
  },
  setSelectedLoadLatestTime(context, time) {
    context.commit("SET_SELECTED_LOAD_LATEST_TIME", time);
    context.commit("SET_LOAD_WINDOW");
  },
  setSelectedDeliveryEarliestDate(context, date) {
    context.commit("SET_SELECTED_DELIVERY_EARLIEST_DATE", date);
		context.commit("SET_DELIVERY_WINDOW");
  },
  setSelectedDeliveryEarliestTime(context, time) {
    context.commit("SET_SELECTED_DELIVERY_EARLIEST_TIME", time);
		context.commit("SET_DELIVERY_WINDOW");
  },
  setSelectedDeliveryLatestDate(context, date) {
    context.commit("SET_SELECTED_DELIVERY_LATEST_DATE", date);
		context.commit("SET_DELIVERY_WINDOW");
  },
  setSelectedDeliveryLatestTime(context, time) {
    context.commit("SET_SELECTED_DELIVERY_LATEST_TIME", time);
		context.commit("SET_DELIVERY_WINDOW");
  },
  setShipmentDateTimeWindows(context) {
    context.commit("SET_SHIPMENT_DATE_TIME_WINDOWS")
  },
  setLoadWindow(context) {
    context.commit("SET_LOAD_WINDOW")
  },
  setDeliveryWindow(context) {
    context.commit("SET_DELIVERY_WINDOW")
  },
  setIsEditMode(context, value) {
    context.commit("SET_IS_EDIT_MODE", value)
  },
	set3PoNumber(context, thirdPartyPONumber) {
    context.commit("SET_3PO_NUMBER", thirdPartyPONumber);
  },
	setTemplateId(context, value){
		context.commit("SET_TEMPLATE_ID", value);
	}
};

const getters = {
  filteredConsignees: state => {
    if (state.selectedProducts.length > 0) {
      return state.consignees.filter(consignee => consignee.billTo == state.selectedProducts[0].deliverySite.billTo);
    }

    return state.consignees;
  },
  getSelectedCommodity(state){
    return state.selectedCommodity;
  },
	getTemplateId(state){
		return state.templateId;
	},
  getSelectedDeliveryDate(state) {
    return state.selectedDeliveryDate
  },
  getSelectedDeliveryTime(state) {
    return state.selectedDeliveryTime
  },
	getDivisionFromNewOrderTender(state){
		return state.division;
	}
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
