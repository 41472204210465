import axios from "axios";
import urls from "@/shared/urls";
import {
  handleRequestError,
} from "../shared/utils/response-error-handler";

var qs = require('qs');

export default {
  async get(data) {
    const customers = await axios.get(`${urls.CustomersUrl}`, {
      headers: {

      },
      params: {
        //customerId: data.id
      }
    });

    return customers;
  },

  async getById(requestData) {
    const getUserResult = await axios.get(`${urls.CustomersUrl}/${requestData.customerId}`, {
      headers: {

      }
    });
    return getUserResult;
  },

  async getCustomerPermissions(data) {

    const customer = await axios.get(
      `${urls.CustomerPermissionsUrl}`, {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        },
        params: {
          customerId: data.id
        }
      }
    );

    return customer;
  },

  async update(requestData) {

    const user = await axios.put(
      `${urls.CustomersUrl}/${requestData.id}`,

      requestData.customerData, {
        headers: {

        }
      }
    );

    return user;
  },

  async updateCustomerPermissions(requestData) {

    const user = await axios.put(
      `${urls.CustomerPermissionsUrl}/${requestData.customerId}`,

      requestData.data, {
        headers: {

        }
      }
    );

    return user;
  },

  async create(requestData) {
    const user = await axios.post(
      `${urls.CustomersUrl}`,
      requestData.customerData, {
        headers: {

        }
      }
    );

    return user;
  },

  async delete(requestData) {

    const user = await axios.delete(
      `${urls.CustomersUrl}/${requestData.id}`, {
        headers: {

        }
      }
    );

    return user;
  },

	async getAssignedCustRefNumbPermissions(customerId){
		try {
			const response = await axios.get(`${urls.CustomerReferenceNumberTypeUrl}/Assigned/${customerId}`);
			if(response.status === 200)
				return response.data;
			return null;
		}catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	},

	async getAvailableCustRefNumbPermissions(customerId){
		try {
			const response = await axios.get(`${urls.CustomerReferenceNumberTypeUrl}/Available/${customerId}`);
			if(response.status === 200)
				return response.data;
			return null;
		}catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	},

	async assignOrUpdateCustRefNumbPermissions(requestData){
		try {
			const response = await axios.put(`${urls.CustomerReferenceNumberTypeUrl}/Assign`, requestData);
			return {
				data: response.data,
				success: true
			}
		}catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	},

	async getAssignedCustUserRefNumbPermissions(){
		try {
			const response = await axios.get(`${urls.CustomerUserReferenceNumberTypeUrl}/Assigned`);
			if(response.status === 200)
				return response.data;
			return null;
		}catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	},

	async assignCustUserRefNumbPermissions(requestData){
		try {
			const response = await axios.put(`${urls.CustomerUserReferenceNumberTypeUrl}/Assign`, requestData);
			return {
				data: response.data,
				success: true
			}
		}catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
	},
};
