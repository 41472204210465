import moment from 'moment';

export function formatDisplayDate(selectedDeliveryTime, selectedDeliveryDate = null, selectedFromCustomDateTime = null, selectedToCustomDateTime) {
  var shipmentStartDateTime, shipmentEndDateTime;
  switch (selectedDeliveryTime) {
    case "morning":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(6)
        .format();

      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(12)
        .format();
      break;
    case "afternoon":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(12)
        .format();

      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(18)
        .format();
      break;

    case "evening":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(18)
        .format();

      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(23)
        .minutes(59)
        .second(59)
        .format();
      break;

    case "custom":
      shipmentStartDateTime = moment(selectedFromCustomDateTime).format();
      shipmentEndDateTime = moment(selectedToCustomDateTime).format();
      break;
    default:
      break;
  }

  return `${moment(shipmentStartDateTime).format(
        "MMMM Do YYYY, h:mm a"
      )} - ${moment(shipmentEndDateTime).format("MMMM Do YYYY, h:mm a")}`;
}

export function formatShipmentStartAndEndDateTime(selectedDeliveryTime, selectedDeliveryDate, selectedFromCustomDate,
  selectedFromCustomTime, selectedToCustomDate, selectedToCustomTime) {

  var shipmentStartDateTime, shipmentEndDateTime;
  switch (selectedDeliveryTime) {
    case "morning":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(6)
        .format("YYYY-MM-DD hh:mm:ss a");
      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(12)
        .format("YYYY-MM-DD hh:mm:ss a");
      break;
    case "afternoon":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(12)
        .format("YYYY-MM-DD hh:mm:ss a");
      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(18)
        .format("YYYY-MM-DD hh:mm:ss a");
      break;
    case "evening":
      shipmentStartDateTime = moment(selectedDeliveryDate)
        .hours(18)
        .format("YYYY-MM-DD hh:mm:ss a");
      shipmentEndDateTime = moment(selectedDeliveryDate)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .format("YYYY-MM-DD hh:mm:ss a");
      break;
    case "custom":
      shipmentStartDateTime = moment(
        `${selectedFromCustomDate}T${selectedFromCustomTime}`
      ).format("YYYY-MM-DD hh:mm:ss a");
      shipmentEndDateTime = moment(
        `${selectedToCustomDate}T${selectedToCustomTime}`
      ).format("YYYY-MM-DD hh:mm:ss a");
      break;
    default:
      break;
  }

  return {
    shipmentStartDateTime: shipmentStartDateTime,
    shipmentEndDateTime: shipmentEndDateTime
  }
}

export function utcDateToLocal(utcDate) {
  return moment
    .utc(utcDate)
    .local()
    .format("MM/DD/YYYY hh:mm a")
    .toString();
}

export function toClientReadableDate(dateToFormat) {
  return moment(dateToFormat)
    .format("MM/DD/YYYY hh:mm a")
    .toString();
}

export function getDateInterval(startDate, startTime, endDate, endTime) {
  var startDateTime = moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ss');
  var endDateTime = moment(`${endDate} ${endTime}`).format('YYYY-MM-DDTHH:mm:ss');
  return `${startDateTime}/${endDateTime}`
}

export function dateToClientReadable(dateToFormat) {
  return moment(dateToFormat).format('MM/DD/YYYY h:mm a').toString()
}

export function convertTime12to24(time12h){
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
