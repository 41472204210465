// logStorage.js

let logStorage = [];

const addToLogStorage = (logData) => {
  logStorage.push(logData)
}

const getLogStorage = () => {
  return logStorage
}

const clearLogs = () => {
	logStorage = [];
}

export { addToLogStorage, getLogStorage, clearLogs };