import axios from "axios";
//import _ from "lodash";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  masterPermissions: []
};

const mutations = {
  SET_MASTER_PERMISSIONS: (state, payload) => {
    state.masterPermissions = payload;
  }
};
const actions = {
  async getAllMasterPermissions(context, data) {
    const permissions = await axios.get(`${urls.MasterPermissionsUrl}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_MASTER_PERMISSIONS", permissions.data);
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
