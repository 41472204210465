import axios from "axios";
import _ from "lodash";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  customers: [],
  selectedCustomer: null
};

const mutations = {
  SET_CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },
  SET_SELECTED_CUSTOMER: (state, payload) => {
    state.selectedCustomer = payload;
  },
  ADD_CUSTOMER: (state, payload) => {
    state.customers.push(payload);
  },
  UPDATE_CUSTOMER: (state, payload) => {
    var index = _.findIndex(state.customers, function(item) {
      return item.id == payload.id;
    });
    state.customers.splice(index, 1, payload);
  },
  DELETE_CUSTOMER: (state, payload) => {
    var index = _.findIndex(state.customers, function(item) {
      return item.id === payload;
    });
    state.customers.splice(index, 1);
  }
};
const actions = {
  async getAllCustomers(context, data) {
    const customers = await axios.get(urls.CustomersUrl, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_CUSTOMERS", customers.data);
  },
  async getCustomer(context, data) {
    const customer = await axios.get(`${urls.CustomersUrl}/${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });
    context.commit("SET_SELECTED_CUSTOMER", customer.data);
    return customer.data;
  },
  async addNewCustomer(context, requestData) {
    const customer = await axios.post(urls.CustomersUrl, requestData.data, {
      headers: {
        
      }
    });
    context.commit("ADD_CUSTOMER", customer.data);
  },
  async updateCustomer(context, requestData) {
    const customer = await axios.put(
      `${urls.CustomersUrl}/${requestData.data.id}`,
      requestData.data,
      {
        headers: {
          
        }
      }
    );
    context.commit("UPDATE_CUSTOMER", customer.data);
  },
  async deleteCustomer(context, requestData) {
    await axios.delete(`${urls.CustomersUrl}/${requestData.id}`, {
      headers: {
        
      }
    });
    context.commit("DELETE_CUSTOMER", requestData.id);
  },
  async setSelectedCustomer(context, customer) {
    context.commit("SET_SELECTED_CUSTOMER", customer);
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
