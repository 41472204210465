<template id="templSilentSignInCallBack" >
  <div id="divSilentSignInCallBack" class="app flex-row align-items-center">
    <div id="divSilentSignInCallBackParent" class="container vld-parent">
      <b-row id="rowSilentSignInCallBack" class="justify-content-center">
        <b-col id="colSilentSignInCallBack" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpSilentSignInCallBack">
            <b-card id="crdSilentSignInCallBack" no-body class="p-4">
              <b-card-body id="crdBdySilentSignInCallBack">
                <div id="divTFLogo" class="body-logo-container">
                  <img id="imgTFLogo" src="/img/tripfocus.png" class="pb-4" alt="TripFocus" />
                </div>
                <b-row id="rowSignIn">
                  <b-col id="colSignIn" cols="12">
                    <h3 data-testid="hdrSignIn" id="hdrSignIn" class="text-center pb-3">Signing you in to TripFocus</h3>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";

export default {
  name: "SilentSignInCallback.vue",
  mounted() {
    vuexOidcProcessSilentSignInCallback();

    this.$router.push({ name: "dashboard" });
  }
};
</script>
