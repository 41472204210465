import { v4 as uuidv4 } from 'uuid';
/**
 * Model class needed to log in Logging Api
 */
export class LogEventModel{
    /**
     * @param {String} logLevel - Level of the error to log (WARNING, ERROR, INFORMATION, VERBOSE)
     * @param {Date} logDate - Date of the error logged.
     * @param {String} correlationId - Fresh generated correlationId.
     * @param {String} requestId - Fresh generated requestId.
     * @param {String} message - Message to log.
     * @param {String} messageParameters - Parameters to display in the message.
     * @param {Object} context - Context to log.
     */
    constructor(
        logLevel,
        logDate,
        correlationId,
        requestId,
        message,
        messageParameters,
        context
    ){
        this.logLevel = logLevel ? logLevel : 'ERROR';
        this.logDate = logDate ? logDate : new Date();
        this.correlationId = correlationId ? correlationId : uuidv4();
        this.requestId = requestId ? requestId : uuidv4();
        this.message = message ? message : "Error message from TripFocus UI.";
        this.messageParameters = messageParameters ? messageParameters : "";
        this.context = context;
    }
}