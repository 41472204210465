import axios from "axios";
import urls from "../../shared/urls";
import CustomerRepository from "@/services/customerRepository";

var jwtDecode = require("jwt-decode");

const getDefaultUser = () => {
	return {
		userName: null,
		jwtToken: null,
		jwtTokenExpiration: null,
		userData: {},
		changePassword: null,
		resetToken: null,
		email: null,
		roles: [],
		decodedToken: {},
		refreshToken: null,
		refNumTypesAvailable: [],
		refNumTypesSelected: []
	};
};

const state = {
	...getDefaultUser(),
	displayName: ""
};

const mutations = {
	SET_USER: (state, payload) => {
		Object.assign(state, payload);
	},
	CLEAR_USER: (state) => {
		Object.assign(state, getDefaultUser());
	},
	SET_BILL_TOS: (state, payload) => {
		state.billTos = payload;
	},
	SET_USER_AVAILABLE_REF_NUM_TYPES: (state, payload) => {
		state.refNumTypesAvailable = payload;
	},
	SET_USER_ASSIGNED_REF_NUM_TYPES: (state, payload) => {
		state.refNumTypesSelected = payload;
	}
};

const actions = {
	async loginUser(context, authData) {
		const response = await axios.post(urls.AuthenticationUrl, {
			username: authData.username,
			password: authData.password,
		});

		if (response.status === 200) {
			const { token, resetPasswordToken } = response.data;
			const decoded = jwtDecode(token);
			const decodedRoles = Array.isArray(decoded.role) ? decoded.role : [decoded.role];

			const userPayload = {
				userName: decoded.unique_name,
				jwtToken: token,
				jwtTokenExpiration: decoded.exp,
				roles: decodedRoles,
				changePassword: decoded.change_password,
				email: decoded.name,
				resetToken: resetPasswordToken,
				decodedToken: decoded,
				refreshToken: response.data.refreshToken,
			};

			context.commit("SET_USER", userPayload);
		}
	},

	logoutUser(context) {
		context.commit("CLEAR_USER");
	},
	
	async resetPasword(context, authData) {
		const response = await axios.post(`${urls.UsersUrl}/resetpassword`, authData);
		
		if (response.status === 200) {
			const { token, resetPasswordToken } = response.data;
			const decoded = jwtDecode(token);
			const decodedRoles = Array.isArray(decoded.role) ? decoded.role : [decoded.role];

			const userPayload = {
				userName: decoded.unique_name,
				jwtToken: token,
				jwtTokenExpiration: decoded.exp,
				roles: decodedRoles,
				changePassword: decoded.change_password,
				email: decoded.name,
				resetToken: resetPasswordToken,
				decodedToken: decoded,
				refreshToken: response.data.refreshToken,
			};

			context.commit("SET_USER", userPayload);
		}
	},
	
	async changePasword(context, { passwordInfo, jwtToken }){
		const config = {
			headers: {
				Authorization: `Bearer ${jwtToken}`,
			},
		};
	
		await axios.post(`${urls.UsersUrl}/changepassword`, passwordInfo, config);
	},

	async sendResetPaswordEmail(context, email) {
		await axios.post(`${urls.UsersUrl}/forgotpassword`, {
			email: email
		});
	},

 	async useRefreshToken(context, authData) {
		const response = await axios.post(`${urls.UsersUrl}/refreshtoken`, {
			refreshToken: authData.RefreshToken,
		});

		if (response.status !== 200) return false;

		const { token, resetPasswordToken } = response.data;
		const decoded = jwtDecode(token);
		const decodedRoles = Array.isArray(decoded.role) ? decoded.role : [decoded.role];

		const userPayload = {
			userName: decoded.unique_name,
			jwtToken: token,
			jwtTokenExpiration: decoded.exp,
			roles: decodedRoles,
			changePassword: decoded.change_password,
			email: decoded.name,
			resetToken: resetPasswordToken,
			decodedToken: decoded,
			refreshToken: response.data.refreshToken,
		};

		context.commit("SET_USER", userPayload);

		return true;
	},

	async fetchUserAvailableRefNumTypes(context) {
		// Wait for the oidcStore module to finish initializing
		await context.dispatch('oidcStore/getOidcUser', null, { root: true });

		// Now you can safely access oidcStore.user
		const currentUser = context.rootState.oidcStore.user;
		
		if(currentUser){
			const availableCustRefNumPermissions = await CustomerRepository.getAssignedCustRefNumbPermissions(currentUser.customer_id);
	
			context.commit("SET_USER_AVAILABLE_REF_NUM_TYPES", availableCustRefNumPermissions);
		}
	},

	async fetchUserAssignedRefNumTypes(context) {
		// Wait for the oidcStore module to finish initializing
		await context.dispatch('oidcStore/getOidcUser', null, { root: true });

		const assignedCustRefNumPermissions = await CustomerRepository.getAssignedCustUserRefNumbPermissions();

		context.commit("SET_USER_ASSIGNED_REF_NUM_TYPES", assignedCustRefNumPermissions);
	}
};

const getters = {
	loggedInUser: (state, getters, rootState) => {
		const decodedToken = jwtDecode(rootState.oidcStore.access_token);
		let displayName = decodedToken.email;
		let kagConnectMigration = false;
		let defaultSubDomain = "kag";

		if (decodedToken.first_name) {
			displayName = decodedToken.first_name;
		}

		if (decodedToken.kag_connect_migration) {
			kagConnectMigration = true;
		}

		if (decodedToken.TripFocusSubDomain) {
			defaultSubDomain = decodedToken.TripFocusSubDomain;
		}

		return {
			decodedToken,
			customerId: decodedToken.customer_id,
			userId: decodedToken.sub,
			displayName,
			email: decodedToken.name,
			changePassword: decodedToken.change_password,
			updateProfile: kagConnectMigration,
			defaultSubDomain,
		};
	}
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};