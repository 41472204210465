import axios from "axios";
import urls from "@/shared/urls";
var qs = require('qs');

var baseUrl = urls.RelationshipsBaseUrl;


export default {

  async getDivisions(data) {    
    const response = await axios.get(`${baseUrl}/divisions`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },

  async get(data) {
    const response = await axios.get(`${baseUrl}/billtos`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getConsignees(data) {
    const response = await axios.get(`${baseUrl}/consignees`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take,
        includeReadOnly:  (Boolean(data.includeReadOnly))
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getShippers(data) {
    const response = await axios.get(`${baseUrl}/shippers`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        consignee: data.consignee,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getSuppliers(data) {
    const response = await axios.get(`${baseUrl}/suppliers`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        consignee: data.consignee,
        shipper: data.shipper,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getAccountOfs(data) {
    const response = await axios.get(`${baseUrl}/accountofs`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        consignee: data.consignee,
        shipper: data.shipper,
        supplier: data.supplier,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getCommodities(data) {


    const response = await axios.get(`${baseUrl}/commodities`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        consignee: data.consignee,
        shipper: data.shipper,
        supplier: data.supplier,
        accountOf: data.accountOf,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  },
  async getCommodityClasses(data) {

    const response = await axios.get(`${baseUrl}/CommodityClass`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.customerId,
        userId: data.userId,
        division: data.division,
        billTo: data.billTo,
        consignee: data.consignee,
        shipper: data.shipper,
        supplier: data.supplier,
        accountOf: data.accountOf,
        searchTerm: data.searchTerm,
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  }
};
