import axios from "axios";
import urls from "@/shared/urls";
var qs = require('qs');

var baseUrl = urls.CommoditiesBaseUrl;


export default {

  async get() {

    const response = await axios.get(`${baseUrl}/CommodityClass`, {
      headers: {},
      params: {
        // customerId: data.customerId,
        // userId: data.userId,
        // division: data.division,
        // billTo: data.billTo,
        // consignee: data.consignee,
        // shipper: data.shipper,
        // supplier: data.supplier,
        // accountOf: data.accountOf,
        // searchTerm: data.searchTerm,
        // skip: data.skip,
        // take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    });

    return response;
  }
};
