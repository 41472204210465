import axios from "axios";
import urls from "@/shared/urls";
import {LogEventContext} from "@/shared/models/ErrorLogs/LogEventContext.js";
import {LogEventModel} from "@/shared/models/ErrorLogs/LogEventModel.js";
import { v4 as uuidv4 } from 'uuid';

export default {
    /**
     * Method in charge of posting error to LoggingApi.
     * @param {Object} err - Error trace.
     * @param {Object} vm - Component in which error occurred.
     * @param {String} info - Vue specific error information such as lifecycle hooks, events etc.
     */
    async postError(err, vm, info){
        const requestId = uuidv4();
        const correlationId = uuidv4();
        const logEventContext = new LogEventContext(info, err.toString());
        const userAgentString = navigator.userAgent; 
        const message = "Error in {component}, BrowserData: {userAgentString}";
        const messageParameters ={
            component: vm ? vm.$vnode.tag : "Not defined",
            userAgentString: userAgentString
        };
        
        const logEventModel = new LogEventModel('ERROR',  new Date(), correlationId, requestId, message, messageParameters, logEventContext);
        const logErrorEventList = [ logEventModel ];

        try{
            const response = await axios.post(`${urls.LoggingUrl}`, logErrorEventList);
            if(response.status == 201){
                return {
                    data: response.data,
                    loggedError: logEventModel
                };
            }
        }catch(error){
            return error.data ? error.data : error.response.data;
        }
    },

	async postErrors(errors){
		const requestId = uuidv4();
		const correlationId = uuidv4();
		const logErrorEventList = errors.map((error) => {
			const message = "Error in {component}, BrowserData: {browser}";
			const messageParameters ={
				component: error.component ? error.component : "Not defined",
				browser: navigator.userAgent
			};
			const logEventContext = new LogEventContext(error.info, error.message);
			return new LogEventModel('ERROR', new Date(), correlationId, requestId, message, messageParameters, logEventContext);
		});
		try{
			const response = await axios.post(`${urls.LoggingUrl}`, logErrorEventList);
			if(response.status == 201){
				return {
					data: response.data,
					loggedErrors: errors
				};
			}
		}catch(error){
			return error.data ? error.data : error.response.data;
		}
	}
}