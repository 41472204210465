// purpose for this file is to setup/control any functionality turned on/off with a feature flag.
// usage: import { enableSubDomainRedirects } from '@/shared/feature-flags'

var enableSubDomainRedirects = "#{TripFocus.EnableSubDomainRedirects}"; //will be replaced by Octopus;

if (~encodeURIComponent(enableSubDomainRedirects).indexOf("%23%7B")) { //%23%7B = #{
  enableSubDomainRedirects = "false";
}

var customerUrlTextbox = "#{TripFocus.CustomerUrlTextbox}";

if (~encodeURIComponent(customerUrlTextbox).indexOf("%23%7B")) { //%23%7B = #{
  customerUrlTextbox = "true";
}

var showEnvironmentBanner = "#{TripFocus.ShowEnvironmentBanner}";

if (~encodeURIComponent(showEnvironmentBanner).indexOf("%23%7B")) { //%23%7B = #{
  showEnvironmentBanner = "true";
}

var allowBulkImageExports = "#{TripFocus.AllowBulkImageExports}";

if (~encodeURIComponent(allowBulkImageExports).indexOf("%23%7B")) { //%23%7B = #{
  allowBulkImageExports = "true";
}

var enableImagingFdg = "#{TripFocus.EnableImaging.FDG}";
var enableImagingSpg = "#{TripFocus.EnableImaging.SPG}";
var enableImagingCan = "#{TripFocus.EnableImaging.CAN}";

if (~encodeURIComponent(enableImagingFdg).indexOf("%23%7B")) { //%23%7B = #{
  enableImagingFdg = "true";
  enableImagingSpg = "true";
  enableImagingCan = "false";
}

var enableImaging = {
  "FDG": enableImagingFdg === "true",
  "SPG": enableImagingSpg === "true",
  "CAN": enableImagingCan === "true"
};

export {
  enableSubDomainRedirects,
  customerUrlTextbox,
  allowBulkImageExports,
  enableImaging,
  showEnvironmentBanner
}
