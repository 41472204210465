const namespaced = true;

const state = {
  selectedUser: null,
  selectedUserDocTypes: [],
  globalUserDocTypes: [],
  filterSelectedCustomer: null,
  isPermissionsLoaded: false,
  tabIndex: 0,
  newUser: false
};

const mutations = {

  MUTATE_SET_SELECTED_USER: (state, payload) => {
    state.selectedUser = payload;
  },
  MUTATE_SET_SELECTED_USER_DOCTYPES: (state, payload) => {
    state.selectedUserDocTypes = payload;
  },
  MUTATE_SET_GLOBAL_USER_DOCTYPES: (state, payload) => {
    state.globalUserDocTypes = payload;
  },


  MUTATE_SET_FILTER_SELECTED_CUSTOMER: (state, payload) => {
    state.filterSelectedCustomer = payload;
  },
  MUTATE_SET_IS_PERMISSIONS_LOADED: (state, payload) => {
    state.isPermissionsLoaded = payload;
  },
  MUTATE_SET_TAB_INDEX: (state, payload) => {
    state.tabIndex = payload;
  },
  MUTATE_SET_NEW_USER: (state, payload) => {
    state.newUser = payload;
  },
};

const actions = {
  setSelectedUser({
    commit
  }, payload) {
    commit("MUTATE_SET_SELECTED_USER", payload);
  },
  setSelectedUserDocTypes({
    commit
  }, payload) {
    commit("MUTATE_SET_SELECTED_USER_DOCTYPES", payload);
  },

  setGlobalUserDocTypes({
    commit
  }, payload) {
    commit("MUTATE_SET_GLOBAL_USER_DOCTYPES", payload);
  },

  setFilterSelectedCustomer({
    commit
  }, payload) {
    commit("MUTATE_SET_FILTER_SELECTED_CUSTOMER", payload);
  },
  setIsPermissionsLoaded({
    commit
  }, payload) {
    commit("MUTATE_SET_IS_PERMISSIONS_LOADED", payload);
  },
  setTabIndex({
    commit
  }, payload) {
    commit("MUTATE_SET_TAB_INDEX", payload);
  },
  setNewUser({
    commit
  }, payload) {
    commit("MUTATE_SET_NEW_USER", payload);
  },

};

const getters = {
  getSelectedUser: state => {
    return state.selectedUser;
  },
  getSelectedUserDocTypes: state => {
    return state.selectedUserDocTypes;
  },

  getGlobalUserDocTypes: state => {
    return state.globalUserDocTypes;
  },

  getFilterSelectedCustomer: state => {
    return state.filterSelectedCustomer;
  },
  getIsPermissionsLoaded: state => {
    return state.isPermissionsLoaded;
  }

};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
