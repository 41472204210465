import axios from "axios";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  imagePermissions: []
};

const mutations = {
  SET_IMAGE_PERMISSIONS: (state, payload) => {
    state.imagePermissions = payload;
  }
};
const actions = {
  async getAllImagePermissions(context, data) {
    const permissions = await axios.get(`${urls.ImagePermissionsUrl}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      }
    });

    if (permissions.data)
      context.commit("SET_IMAGE_PERMISSIONS", permissions.data);
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
