// purpose for this file is to centralcontrol all constants used among all components
// usage: import { dateTimeFormats, exportOrderImagesLimit } from '@/shared/constants'

//Minimum capacity Quanity.
const minCapacity = {
  FDG: 100,
  SPG: 1,
};
//Maximum capacity for Quantity.
const maxCapacity = {
  FDG: 13500,
  SPG: 50000,
};

const exportOrderImagesLimit = 50; // order tenders to get images limit for download, for performance, limit to 50 files in a zip

const orderResultsMessageThreshold = 500; // show a message that there are more than this many orders that can be returned so they should refine their filters.
const consigneeDashboardMessageThreshold = 500; // show a message that there are more than this many consignees on the dashboard that can be returned so they should refine their filters.

var hereMapsApiKey = "#{TripFocus.HereMapsApiKey}"; //will be replaced by Octopus;
var showErrorsInConsole = "#{TripFocus.ShowErrorsInConsole}"; //will be replaced by Octopus;

var maintenanceMode = "#{TripFocus.MaintenanceModeActive}";

if (~encodeURIComponent(hereMapsApiKey).indexOf("%23%7B")) {
  //%23%7B = #{
  hereMapsApiKey = "ZDGwdcxJZDMLvL8752hwfICLfMc8RrVs2n210tnSumU";
}
if (~encodeURIComponent(showErrorsInConsole).indexOf("%23%7B")) {
  //%23%7B = #{
  showErrorsInConsole = "true";
}
if (~encodeURIComponent(maintenanceMode).indexOf("%23%7B")) {
  //%23%7B = #{
  maintenanceMode = "false";
}

// input and output date formats
const dateTimeFormats = {
  // formats to display the date/time in datepicker
  dateOnlyFormat: "MM/DD/YYYY",
  datetimeFormat: "MM/DD/YYYY hh:mm A",
  datetimeWithSecond: "MM/DD/YYYY hh:mm:ss A",
  timeOnlyFormat: "h:mm A",
  timeOnlyWithSecond: "h:mm:ss A",

  // the formats to store/retrieve to/from $store.state  "YYYY-MM-DDTHH:mm",
  outputDatetimeFormat: "YYYY-MM-DD",
  outputDateFormat: "YYYY-MM-DD",
  outputTimeFormat: "HH:mm", // no seconds right now,

  // format to display in confirmation order
  dayOfMonthFormat: "MMMM Do YYYY, h:mm a", // refer to https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
};

// order delivery custom from/to window
const deliveryWindows = {
  startOffsetHours: 0, // hours, the minium start time from now, right now set it to right now
  minWindowHours: 0, // hours, the minimum window between fromdate and todate
  endCutoffHours: 0.25, // hours, the min to/end date, normally it is 24 hours from now. .25 is 15 minutes. and .8333 is 5 minutes
};

// order delivery date/time -- for preset
const deliveryTimes = {
  morning: {
    text: "Morning",
    value: 1,
    start: "06:00",
    end: "12:00",
  },
  afternoon: {
    text: "Afternoon",
    value: 2,
    start: "12:00",
    end: "18:00",
  },
  evening: {
    text: "Evening",
    value: 3,
    start: "18:00",
    end: "23:59",
  },
  custom: {
    text: "Custom",
    value: 4,
    start: "0:00",
    end: "23:59",
  },
};

const divisions = Object.freeze({
  FDG: "FDG",
  SPG: "SPG",
});

const timeWindows = {
  earliest: "earliest",
  latest: "latest",
};

export {
  dateTimeFormats,
  deliveryWindows,
  deliveryTimes,
  divisions,
  exportOrderImagesLimit,
  orderResultsMessageThreshold,
  consigneeDashboardMessageThreshold,
  hereMapsApiKey,
  showErrorsInConsole,
  timeWindows,
  minCapacity,
  maxCapacity,
  maintenanceMode,
};
