let amountOfErrorsToSend = "#{TripFocus.MaximumErrorsPerRequest}";
let timeToWaitInSeconds = "#{TripFocus.TimeToWaitToCheckErrors}";
let buildMode = "#{TripFocus.BuildMode}";
let environment = "#{TripFocus.Environment}";

if (~encodeURIComponent(buildMode).indexOf("%23%7B")) {
	amountOfErrorsToSend = 5;
	timeToWaitInSeconds = 5000;
	environment = 'Development'
}

export { amountOfErrorsToSend, timeToWaitInSeconds, environment };
