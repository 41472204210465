import axios from "axios";
import urls from "@/shared/urls";


var baseUrl = urls.MasterPermissionsBaseUrl;
var endpoint = "/masterpermissions";

export default {
  async get(data) {

    const response = await axios.get(baseUrl + endpoint + '?customerId=' + data.customerId, {
      headers: {}
    });

    return response;
  }
};
