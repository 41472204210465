import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import {
  createStore
} from 'vuex-extensions'

import user from './modules/user';
import newOrderTender from './modules/order-tender/newOrderTender';
import orderTemplate from './modules/order-template/orderTemplate';
import newOrderTemplate from './modules/order-template/newOrderTemplate';
import order from './modules/orders/order';
import internalAdminCustomer from './modules/internal-admin/Customer'
import internalAdminMasterPermission from './modules/internal-admin/MasterPermission';
import internalAdminImagePermission from "./modules/internal-admin/ImagePermission";
import internalAdminBillTos from './modules/internal-admin/BillTos';
//import internalAdminUser from './modules/internal-admin/User';
import externalAdminUser from './modules/external-admin/user';
import externalAdminImagePermission from "./modules/external-admin/ImagePermission";
import externalAdminCustomer from './modules/external-admin/customer';
import orderImage from './modules/orders/orderImage'
import notification from './modules/notifications/notification'
import location from './modules/orders/location'
import companyMultiSelects from './modules/company-multiselects/CompanyMultiSelects'
import users from './modules/users/Users'
import customers from './modules/customers/Customers'
import dashboard from './modules/dashboard/Dashboard'



import {
  vuexOidcCreateStoreModule
} from 'vuex-oidc'

import {
  oidcSettings
} from '@/config/oidc'


Vue.use(Vuex);


const vuexPersist = new VuexPersist({
  key: 'kag-customer-portal',
  storage: localStorage,
  reducer: (state) => ({
    user: state.user
  }),
});

//export default new Vuex.Store({
export default createStore(Vuex.Store, {

  plugins: [vuexPersist.plugin],
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    user,
    newOrderTender,
    orderTemplate,
    newOrderTemplate,
    order,
    internalAdminCustomer,
    internalAdminMasterPermission,
    internalAdminImagePermission,
    internalAdminBillTos,
    //  internalAdminUser,
    externalAdminUser,
    externalAdminCustomer,
    externalAdminImagePermission,
    orderImage,
    notification,
    location,
    companyMultiSelects,
    users,
    customers,
    dashboard,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
      // spread your oidcSettings and define a userStore of your choice
      // {
      //   ...oidcSettings,
      //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
      // },
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        // userLoaded: (user) => {

        //   console.log('OIDC user is loaded:', user)
        // },
        // userUnloaded: () => console.log('OIDC user is unloaded'),
        // accessTokenExpiring: () => console.log('Access token will expire'),
        // accessTokenExpired: () => console.log('Access token did expire'),
        // silentRenewError: () => console.log('OIDC user is unloaded'),
        // userSignedOut: () => console.log('OIDC user is signed out'),
        // oidcError: (payload) => console.log('OIDC error', payload)
      }
    )

  }
})
