/**
 * Model for the context needed in LoggingApi endpoint.
 */
export class LogEventContext{
    /**
     * @param {String} info - Vue specific error information such as lifecycle hooks, events etc.
     * @param {Object} error - Error/Warning Stack trace.
     */
    constructor(
        info,
        error
    ){
        this.info = info;
        this.error = error;
    }
}