<template>
  <div @click="clicked">
    <i class="icon-envelope font-3xl"></i>
    <b-badge pill variant="info" v-if="newMessageCount > 0">{{
      newMessageCount
    }}</b-badge>
  </div>

  <!-- 
  <AppHeaderDropdown no-caret>
removed right from this AppHeaderDropdown tag 
  <template slot="header">
      <div @click="clicked">
        <i class="icon-envelope font-3xl"></i>
        <b-badge pill variant="info" v-if="newMessageCount > 0">{{
          newMessageCount
        }}</b-badge>
      </div>
    </template>
    <template slot="dropdown">
      <div class="dropdown-menu-lg">
        <b-dropdown-header tag="div" class="text-center"
          ><strong
            >You have {{ newMessageCount }} unread messages</strong
          ></b-dropdown-header
        >
        <b-dropdown-item>
          <div class="message">
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">Just now</small>
            </div>
            <div class="text-truncate font-weight-bold">
              <span class="fa fa-exclamation text-danger"></span> Important
              message
            </div>
            <div class="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt...
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item href="#" class="text-center"
          ><strong>View all messages</strong></b-dropdown-item
        >
      </div>
    </template> 
  </AppHeaderDropdown> -->
</template>
<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import { mapState } from "vuex";
export default {
  name: "DefaultHeaderDropdownMssgs",
  components: {
    AppHeaderDropdown
  },
  methods: {
    clicked() {
      this.$router.push({ name: "messages" });
      return false;
    }
  },
  computed: {
    ...mapState("notification", ["newMessageCount"])
  }
};
</script>
