import axios from 'axios';
import urls from '@/shared/urls';
var qs = require('qs');

const namespaced = true;

const state = {
};

const mutations = {
};

const actions = {
  async getOrderLocationData(context, requestData) {
    

    
    var image = await axios.get(urls.LocationsUrl, {
      headers: {
        //
      },
      params: {
        orderNumber: requestData.orderNumber,
        division: requestData.division
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }); 
 
    return image.data;
  }
};

const getters = {
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
