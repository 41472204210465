import axios from 'axios';
import store from '@/store/store';
import urls from '@/shared/urls';
import { v4 as uuidv4 } from 'uuid';

export default function setup() {
  if (urls.UseBaseUrl) axios.defaults.baseURL = urls.BaseUrl;

  // Add a request interceptor
  axios.interceptors.request.use(
    config => {
      config.headers.common['X-Correlation-ID'] = uuidv4();
      config.headers.common['X-Request-ID'] = uuidv4();
			config.headers.common['X-ClientID'] = store.state.oidcStore.user?.customer_id || null;
      
      const token = store.state.oidcStore.access_token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor
  axios.interceptors.response.use(
    response =>{
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error =>{
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
}
