import axios from "axios";
import urls from "@/shared/urls";
var qs = require("qs");

var baseUrl = urls.NotificationsBaseUrl;
var endpoint = "/messages";
var categoriesEndpoint = "/messagecategories";

export default {
  async get(data) {
    const result = await axios.get(baseUrl + endpoint, {
      headers: {

      },
      params: {
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat"
        });
      }
    });

    return result.data;
  },

  async getById(id) {
    const result = await axios.get(`${baseUrl + endpoint}/${id}`, {
      headers: {

      }
    });

    return result.data;
  },

  async create(requestData) {

    const user = await axios.post(
      baseUrl + endpoint,
      requestData, {
        headers: {

        }
      }
    );

    return user;
  },

  async delete(id) {

    await axios.delete(
      `${baseUrl + endpoint}/${id}`, {
        headers: {}
      }
    );

    return true;
  },


  async getCategories(data) {
    const result = await axios.get(baseUrl + categoriesEndpoint, {
      headers: {

      },
      params: {
        skip: data.skip,
        take: data.take
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat"
        });
      }
    });

    return result.data;
  },

};
