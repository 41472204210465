import axios from "axios";
//import _ from "lodash";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  billTos: []
};

const mutations = {
  SET_BILL_TOS: (state, payload) => {
    state.billTos = payload;
  }
};
const actions = {
  async getAllBillTos(context, data) {
    const billTos = await axios.get(
      `${urls.IdentityServerUrl}/BillTos/GetBillTosFor`,
      {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        }
      }
    );    
    context.commit("SET_BILL_TOS", billTos.data);
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
