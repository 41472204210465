<template id="tmplApp">
  <div id="divApp">
    <vue-snotify id="snotBlank"></vue-snotify>
    <component id="cmpTrans" :is="layout">
      <transition id="trnsFade" name="fade" mode="out-in">
        <component v-bind:is="layout"></component>
      </transition>
    </component>
    <Chatbot v-show="showChatBot" />
  </div>
</template>

<script>
import store from "@/store/store";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const UserMessagesRepository = RepositoryFactory.get("userMessagesRepository");
import { enableSubDomainRedirects } from "@/shared/feature-flags";
import { mapActions, mapGetters } from "vuex";
import Chatbot from "@/components/chatbot/Chatbot";
import {
  allowsAiAccess
} from "@/shared/utils/permissions";

export default {
  components: {
    Chatbot
  },
  name: "App.vue",
  async mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    await this.loadUserRefNumConfiguration();
    setInterval(this.loadUndreadMessageCount, 30 * 1000);
  },
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback", "removeOidcUser"]),
    ...mapActions("user", ["fetchUserAvailableRefNumTypes", "fetchUserAssignedRefNumTypes"]),
    async loadUndreadMessageCount() {
      try {
        if (store.state.oidcStore.access_token) {
          let count = await UserMessagesRepository.getUnreadCount();
          store.dispatch("notification/setNewMessageCount", count);
        }
      } catch (error) {
        //Ignore Issues 
      }
    },
    async loadUserRefNumConfiguration() {
      try {
        if (store.state.oidcStore.access_token) {
          await this.fetchUserAvailableRefNumTypes();
          await this.fetchUserAssignedRefNumTypes();
        }
      } catch (error) {
        //Ignore Issues 
      }
    },
    userLoaded: function () {
      if (enableSubDomainRedirects == "true") {
        var userSubDomain = "";

        try {
          userSubDomain = this.loggedInUser.defaultSubDomain;
        } catch (e) {
          userSubDomain = "kag";
        }
        console.log(userSubDomain);

        const host = window.location.host;
        const parts = host.split(".");

        let isProd = true;

        let nonProdSubDomains = ["dev", "qa", "training", "uat"];
        let environmentSubDomain = "";

        if (nonProdSubDomains.indexOf(parts[0]) >= 0) {
          isProd = false;
          environmentSubDomain = parts[0] + ".";
        } else if (nonProdSubDomains.indexOf(parts[1]) >= 0) {
          isProd = false;

          environmentSubDomain = parts[1] + ".";
        }

        console.log("isProd: " + isProd);

        console.log(parts);

        let part1 = userSubDomain + ".";
        let part2 = environmentSubDomain;
        let part3 = parts[parts.length - 2] + ".";
        let part4 = parts[parts.length - 1];

        var url =
          window.location.protocol +
          "//" +
          part1 +
          part2 +
          part3 +
          part4 +
          "/oidc-silent-renew";

        let shouldRedirect = false;

        if (isProd) {
          if (parts[0] === "www" || parts[0] === "tripfocus") {
            shouldRedirect = true;
            console.log("Prod should redirect.");
          } else {
            if (parts[0] !== userSubDomain) {
              shouldRedirect = true;
              console.log("Prod should redirect because wrong sub domain");
            }
          }
        } else {
          if (nonProdSubDomains.indexOf(parts[0]) >= 0) {
            shouldRedirect = true;
            console.log("Non Prod should redirect.");
          } else {
            if (parts[0] !== userSubDomain) {
              shouldRedirect = true;
              console.log("Non Prod should redirect because wrong sub domain");
            }
          }
        }

        if (shouldRedirect) {
          // console.log("Removing user on old domain");
          // this.removeOidcUser();
          console.log("redirecting to:  " + url);
          window.location.href = url;
          return false;
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["loggedInUser"]),
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + "-layout";
      }
    },
    showChatBot: {
      get: function () {
        return allowsAiAccess(store);
      }
    }
  },
  destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
  }
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";

/* Import Font Awesome Icons Set */
// $fa-font-path: "~font-awesome/fonts/";
// @import "~font-awesome/scss/font-awesome.scss";

@import "~@fortawesome/fontawesome-free/css/all.css";

/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
//@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

@import "~vue-snotify/styles/material.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
