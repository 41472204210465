import axios from "axios";
import urls from "@/shared/urls";


var baseUrl = urls.RelationshipsBaseUrl;
var endpoint = "/v3/filters";

export default {
  
  async get(data) {

    const response = await axios.get(baseUrl + endpoint, {
      headers: {},
      params: {}
    });

    return response;
  }
}
