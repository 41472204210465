import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import { maintenanceMode } from "@/shared/constants";

import OidcCallback from "../views/OidcCallback.vue";
import OidcCallbackError from "../views/OidcCallbackError.vue";
import SilentSignInCallback from "../views/SilentSignInCallback.vue";

// Views - Pages
const Dashboard = () => import("@/views/Dashboard");
const Page404 = () => import("@/views/Page404");
const Maintenance = () => import("@/views/Maintenance");
//const Page500 = () => import('@/views/Page500');
const Login = () => import("@/views/Login");
const ForgotPassword = () => import("@/views/ForgotPassword");
const OrderTenders = () => import("@/views/order-tender/OrderTenders");
const NewOrderTender = () => import("@/views/order-tender/NewOrderTender");
const EditOrderTender = () => import("@/views/order-tender/EditOrderTender");
const OrderTemplates = () => import("@/views/order-template/Templates");
const OrderDetails = () => import("@/views/order/OrderDetails");
const NewOrderTemplate = () => import("@/views/order-template/NewTemplate");
const EditOrderTemplate = () => import("@/views/order-template/EditTemplate");
const ResetPassword = () => import("@/views/ResetPassword");
const UserProfile = () => import("@/views/user/Profile");
//const InternalAdminCustomers = () => import("@/views/internal-admin/Customers");
//const InternalAdminEditCustomer = () => import("@/views/internal-admin/CustomerUsers");
// const ExternalAdminUsers = () => import("@/views/external-admin/Users");

const Notifications = () => import("@/views/notifications/Notification");

//new user views
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");
const NewUser = () => import("@/views/users/User");

//new customer views
const Customers = () => import("@/views/customers/Customers");
const Customer = () => import("@/views/customers/Customer");

//admin message views
const Messages = () => import("@/views/messages/Messages");
const Message = () => import("@/views/messages/Message");
const MessageDetails = () => import("@/views/messages/MessageDetails");

//users messages
const UserMessages = () => import("@/views/messages/UserMessages");
const Help = () => import("@/views/help/Help");

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      isPublic: true,
      layout: "no-menu",
    },
  },
  {
    path: "/oidc-callback", // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      layout: "no-menu",
    },
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
      layout: "no-menu",
    },
  },

  {
    path: "/oidc-silent-renew",
    name: "SilentSignInCallback",
    component: SilentSignInCallback,
    meta: {
      layout: "default-container",
      label: "Profile",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      layout: "no-menu",
      isPublic: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      isPublic: true,
      layout: "no-menu",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "default-container",
      label: "Dashboard",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfile,
    meta: {
      layout: "default-container",
      label: "Profile",
    },
  },
  {
    path: "/order-tenders",
    name: "order-tenders",
    component: OrderTenders,
    meta: {
      layout: "default-container",
      label: "Order Tenders",
      stateModule: "order", // this is the state module name which is used to fire reset state and reload event
    },
  },
  {
    path: "/new-order-tender",
    name: "new-order-tender",
    component: NewOrderTender,
    meta: {
      layout: "default-container",
      label: "New Order Tender",
    },
  },
  {
    path: "/edit-order-tender/:division?:id",
    name: "edit-order-tender",
    component: EditOrderTender,
    meta: {
      layout: "default-container",
      label: "Edit Order Tender",
    },
  },
  {
    path: "/order-templates",
    name: "order-templates",
    component: OrderTemplates,
    meta: {
      layout: "default-container",
      label: "Order Templates",
      stateModule: "orderTemplate", // this is the state module name which is used to fire reset state and reload event
    },
  },
  {
    path: "/order-details/:division?/:id",
    name: "order-details",
    component: OrderDetails,
    meta: {
      layout: "default-container",
      label: "Order Details",
    },
  },
  {
    path: "/new-order-template",
    name: "new-order-template",
    component: NewOrderTemplate,
    meta: {
      layout: "default-container",
      label: "Order Templates",
    },
  },
  {
    path: "/edit-order-template/:id",
    name: "edit-order-template",
    component: EditOrderTemplate,
    meta: {
      layout: "default-container",
      label: "Edit Template",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: {
      layout: "default-container",
      label: "Notifications",
    },
  },
  {
    path: "/admin/users",
    name: "external-admin-users",
    component: Users,
    meta: {
      layout: "default-container",
      label: "Users",
    },
  },
  {
    path: "/admin/users/create",
    name: "admin-create-user",
    component: NewUser,
    meta: {
      layout: "default-container",
      label: "Users",
    },
  },
  {
    path: "/admin/users/:id",
    name: "admin-edit-user",
    component: User,
    meta: {
      layout: "default-container",
      label: "Users",
    },
  },

  {
    path: "/internal-admin/customers",
    name: "internal-admin-customer",
    component: Customers, //InternalAdminCustomers,
    meta: {
      layout: "default-container",
      label: "Customers",
    },
  },
  {
    path: "/admin/customers/create",
    name: "admin-create-customer",
    component: Customer,
    meta: {
      layout: "default-container",
      label: "Customers",
    },
  },
  {
    path: "/internal-admin/customers/:id",
    name: "admin-edit-customer",
    component: Customer,
    meta: {
      layout: "default-container",
      label: "Customers",
    },
  },
  {
    path: "/admin/messages",
    name: "admin-messages",
    component: Messages,
    meta: {
      layout: "default-container",
      label: "Messages",
    },
  },
  {
    path: "/admin/messages/create",
    name: "admin-create-message",
    component: Message,
    meta: {
      layout: "default-container",
      label: "Messages",
    },
  },
  {
    path: "/admin/messages/:id",
    name: "admin-edit-message",
    component: MessageDetails,
    meta: {
      layout: "default-container",
      label: "Messages",
    },
  },

  {
    path: "/messages",
    name: "messages",
    component: UserMessages,
    meta: {
      layout: "default-container",
      label: "Messages",
    },
  },

  {
    path: "/help",
    component: Help,
    meta: {
      isPublic: true,
      layout: "default-container",
    },
  },
  {
    path: "/404",
    component: Page404,
    meta: {
      isPublic: true,
      layout: "no-menu",
    },
  },
  {
    path: "*",
    isPublic: true,
    redirect: "/404",
  },
  {
    path: "/maintenance",
    component: Maintenance,
    meta: {
      isPublic: true,
      layout: "no-menu",
    },
  },
];

const router = new Router({
  linkActiveClass: "open active",
  routes,
  mode: "history",
  base: process.env.BASE_URL,

  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

// Global beforeEach guard to check maintenance mode
router.beforeEach((to, _, next) => {
  if (maintenanceMode && maintenanceMode === "true") {
    // If in maintenance mode, redirect to the maintenance page
    if (to.path !== "/maintenance") {
      return next("/maintenance");
    }
  }
  next(); // Proceed to the next route
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
