// this shared across all components
// methods -- use them like a library
// can put all shared const here as well
// usage in component:
//        import mixin from '@/shared/mixin';
//        mixins: [mixin],


import moment from "moment";
import { deliveryWindows, dateTimeFormats, showErrorsInConsole } from '@/shared/constants'

export default {
  data () {
    return {
    }
  },
  created: function () {
  },
  methods: {
    getDeliveryAddress: (deliverySite, defaultIfEmpty, showShort) => {
    // from deliverySite
    if (!deliverySite || deliverySite.id.trim() === 'CALLDIS') return defaultIfEmpty || '';
	
		let ret = deliverySite.name;
		let address = '';
		address += (deliverySite.address) ? deliverySite.address + ' ' : (deliverySite.address1) ? deliverySite.address1 + ' ' : '';
		address += (deliverySite.address2) ? deliverySite.address2 : '';
	
		let tempZip = deliverySite.zipCode || deliverySite.postalCode || "";
	
		if (!showShort) {
			if (address || deliverySite.city || deliverySite.state) {
				ret += ', ' + (address || '');    
				ret += ' ' + (deliverySite.city || '');
				ret += deliverySite.city ? ', '  + (deliverySite.state || '') : '';
				ret += ' ' + (tempZip || '');    
			}
		}
	
		return ret;
    },

    getAddressAllLines(deliverySite, defaultIfEmpty, hideDetail) {
     
    // from deliverySite -- address1/2 and postalCode
    let ret = [];
    if (deliverySite && deliverySite.id.trim() != 'CALLDIS') {
      ret.push(deliverySite.name);
      if (!hideDetail) {
        ret.push(deliverySite.address || '');
        ret.push(deliverySite.address1 || '');
        ret.push(deliverySite.address2 || '');
        ret.push((deliverySite.city || '') +  (deliverySite.city ? ', '  + (deliverySite.state || '') : '') + ' ' + (deliverySite.postalCode || ''));
      }
    }
    return ret.length ? ret.filter(w=> !!w) : [defaultIfEmpty || 'CALLDIS'];
    },

    getMinFromDate(days) { // start of today by default
    days = days || 0; // default can be from now -- used to be day after 1
    var today = moment().startOf('day');
    if (days) {
      today.add(days, 'day');
    }
    return today;
    },
  
    getMinToDate(start, hourWindow) { // start is YYYY-mm-ddThh:mm a string
    // startDate is datetime string in yyyy-m-dd h/mm a format
    if(start === 0){
      let today = moment().startOf('day');
      return today;
    }
    if (start) {
      let today = new moment();
      let startDate = moment(start);
      hourWindow = hourWindow || deliveryWindows.startOffsetHours;
      // if same day as today or within 24 hours, then just add 24 hours from now
      // otherwise -- then just add 0 hour span
      if ( startDate.diff(today, 'hours') <= deliveryWindows.endCutoffHours - deliveryWindows.minWindowHours) {
        hourWindow += deliveryWindows.endCutoffHours;
        today.add(hourWindow, 'hours');
        return today;
      } else {
        hourWindow += deliveryWindows.minWindowHours;
        startDate.add(hourWindow, 'hours'); // no minutes, seconds and ms right now
        return startDate;
      }
    }
    else {
      return new moment().add(deliveryWindows.endCutoffHours, 'hours');
    }
    },

    getDateFromOutput(dateOnly, timeOnly) {
      // let mode = deliveryTimes[timeMode];
      return moment( dateOnly + 'T' + timeOnly, dateTimeFormats.outputDatetimeFormat);
    },

    /**
     * Function that checks variable in octopus to compare if console.logs are available to be printed.
     * @returns Boolean value that indicates if console.log should be printed
     */
    consoleLogPermitted(){
      return showErrorsInConsole === true || "true".localeCompare(showErrorsInConsole) === 0;
    }
  }
}
