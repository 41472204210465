<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        src="/img/avatars/user.png"
        class="img-avatar"
        alt="My Account"
      /> </template
    >\
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Account</strong>
      </b-dropdown-header>
      <b-dropdown-item
        v-if="oidcUser"
        @click="$router.push({ name: 'messages' })"
        ><i class="fas fa-envelope" /> Messages
        <b-badge v-if="newMessageCount > 0" variant="info">{{
          newMessageCount
        }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="oidcUser"
        @click="$router.push({ name: 'profile' })"
      >
        <i class="fa fa-user" /> Profile
      </b-dropdown-item>
      <b-dropdown-item v-if="oidcUser" @click="logout()">
        <i class="fa fa-lock" /> Sign out
      </b-dropdown-item>

      <b-dropdown-item v-if="!oidcUser" @click="login()">
        <i class="fa fa-lock" /> Sign in
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },

  methods: {
    ...mapActions("oidcStore", ["removeOidcUser", "signOutOidc"]),
    login() {
      this.$router.push({ name: "dashboard" });
    },
    logout() {
      this.$store.reset();
      this.signOutOidc();
      setTimeout(function(){ 
        this.$router.push({name: "login"});      
      }, 3000);
    }
  },
  computed: {
    ...mapState("notification", ["newMessageCount"]),
    ...mapGetters("oidcStore", ["oidcUser"])
  }
};
</script>
