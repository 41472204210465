const namespaced = true;

const state = {
  selectedCustomer: null,
  selectedCustomerDocTypes: [],
  globalCustomerDocTypes: [],
  globalMasterPermissions: []
};

const mutations = {

  MUTATE_SET_SELECTED_CUSTOMER: (state, payload) => {
    state.selectedCustomer = payload;
  },

  MUTATE_SET_SELECTED_CUSTOMER_DOCTYPES: (state, payload) => {
    state.selectedCustomerDocTypes = payload
  },


  MUTATE_SET_GLOBAL_CUSTOMER_DOCTYPES: (state, payload) => {
    state.globalCustomerDocTypes = payload
  },
  MUTATE_SET_GLOBAL_MASTER_PERMISSIONS: (state, payload) => {
    state.globalMasterPermissions = payload
  }
};

const actions = {
  setSelectedCustomer({
    commit
  }, payload) {
    commit("MUTATE_SET_SELECTED_CUSTOMER", payload);
  },
  setSelectedCustomerDocTypes({
    commit
  }, payload) {
    commit("MUTATE_SET_SELECTED_CUSTOMER_DOCTYPES", payload);
  },
  setGlobalCustomerDocTypes({
    commit
  }, payload) {
    commit("MUTATE_SET_GLOBAL_CUSTOMER_DOCTYPES", payload);
  },
  setGlobalMasterPermissions({
    commit
  }, payload) {
    commit("MUTATE_SET_GLOBAL_MASTER_PERMISSIONS", payload);
  }
};

const getters = {
  getSelectedCustomer: state => {
    return state.selectedCustomer;
  },
  getSelectedCustomerDocTypes: state => {
    return state.selectedCustomerDocTypes;
  },
  getGlobalCustomerDocTypes: state => {
    return state.globalCustomerDocTypes;
  },
  getGlobalMasterPermissions: state => {
    return state.globalMasterPermissions;
  }
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
