import axios from "axios";
import qs from "qs";
import urls from "../shared/urls";
import {
  handleRequestError,
} from "../shared/utils/response-error-handler";

const baseUrl = urls.OrdersV2Url;

export default {

  /**
   * This method returns a single order from the Orders.Core API.
   *
   * @param {string} division The division the order belongs to. FDG, SPG or CAN
   * @param {number} orderNumber The order number of the TMS order to retrieve.
   * @param {string} preferHeader - The return type the object should be in. 'stop-based' (default) or 'freight-based'
   * @returns {(object|bool)} If found, the order in the requested format.
   * @memberof OrderRepository
   */
  async getById(division, orderNumber, preferHeader) {
    try {
      const orderDetails = await axios.get(`${urls.OrdersV2Url}/${division}/${orderNumber}`, {
        headers: {
          Prefer: preferHeader ? preferHeader : "",
        },
      });

      return {
        data: orderDetails.data,
        success: true,
      };
    } catch (error) {

      return {
        data: handleRequestError(error),
        success: false,
      };

    }
  },

  /**
   * This method returns all orders from the Orders.Core API matching the passed parameters.
   *
   * @param {string[]} orderStatus Array of order statuses to return orders for.
   * @param {number} pageNumber The current page number.
   * @param {number} numberPerPage The number orders to return per page
   * @param {number} preferHeader For returning the data in a different structure. Right now 'return=freight-based' is the only value that works. 
   */
  async get(orderStatus, consignee, pageNumber, numberPerPage, preferHeader) {

    //todo: add in all the other filters. Just did status for now because this function is new and only need by status for the dashboard. 
    //eventually I will switch the orders page to use this and take api calls out of store
    var reqParams = {
      skip: (pageNumber - 1) * numberPerPage,
      take: numberPerPage
    };

    if (orderStatus && orderStatus.length > 0) {
      reqParams['status'] = orderStatus;
    }

    if (consignee && consignee.length > 0) {
      reqParams['consignee'] = consignee;
    }


    reqParams['sort'] = "DELIVERYDATE";

    try {

      let orders = await axios.get(`${baseUrl}`, {
        headers: {
          Prefer: preferHeader ? preferHeader : "",
        },
        params: reqParams,

        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      });

      return {
        data: orders.data,
        success: true,
      };

    } catch (error) {
      return {
        data: handleRequestError(error),
        success: false,
      };
    }
  }
}
