import axios from "axios";
import _ from "lodash";
import urls from "@/shared/urls";

const namespaced = true;

const state = {
  users: [],
  allowedPermissions: null
};

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
  ADD_USER: (state, payload) => {
    state.users.push(payload);
  },
  UPDATE_USER: (state, payload) => {
    var index = _.findIndex(state.users, function (item) {
      return item.id == payload.id;
    });
    state.users.splice(index, 1, payload);
  },
  SET_ALLOWED_PERMISSIONS: (state, payload) => {
    state.allowedPermissions = payload;
  },
  CLEAR_USERS: (state) => {
    state.users = [];
  },
  REMOVE_USERS: (state, users) => {
    state.users = state.users.filter(w => !users.some(u => u.id === w.id));
  }
};
const actions = {
  async getusers(context, data) {
    const users = await axios.get(`${urls.UsersUrl}`, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        customerId: data.id
      }
    });
    //context.commit("SET_USERS", users.data);

    return users;
  },
  async getuser(context, requestData) {
    const getUserResult = await axios.get(`${urls.UsersUrl}/${requestData.userId}`, {
      headers: {
        
      }
    });
    return getUserResult;
  },
  async addNewUser(context, requestData) {
    const user = await axios.post(
      `${urls.IdentityServerUrl}/users`,
      requestData.userData, {
        headers: {
          
        }
      }
    );
    //context.commit("ADD_USER", user.data);

    return user;

  },
  async updateUser(context, requestData) {

    const user = await axios.put(
      `${urls.IdentityServerUrl}/users/${requestData.userId}`,

      requestData.userData, {
        headers: {
          
        }
      }
    );


    //context.commit("UPDATE_USER", user.data);

    return user;
  },
  async deleteUser(context, requestData) {

    const user = await axios.delete(
      `${urls.IdentityServerUrl}/users/${requestData.userId}`, {
        headers: {
          
        }
      }
    );
    // remove from the list
    // context.commit("REMOVE_USERS", [requestData.userData]);

    return user;
  },
  async getAllowedUserPermissions(context, data) {
    const customer = await axios.get(
      `${urls.IdentityServerUrl}/permissions`, {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        },
        params: {
          customerId: data.id
        }
      }
    );
    context.commit("SET_ALLOWED_PERMISSIONS", customer.data);
  },
  clearUsers(context) {
    context.commit("CLEAR_USERS");
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
