import axios from "axios";
import urls from "@/shared/urls";

const namespaced = true;
const defaultValue = "NOTFOUND";

const state = {
  templateId: "",
  templateName: "",
  billTos: [],
  consignees: [],
  commodities: [],
  shippers: [],
  suppliers: [],
  accountOfs: [],
  selectedBillTo: "",
  selectedConsignee: "",
  selectedShipper: "",
  selectedSupplier: "",
  selectedAccountOf: "",
  selectedCommodity: "",
  selectedSplashCommodity:"",
  selectedQuantity: "",
  selectedAmountUnitOfMeasure: "",
  selectedProducts: [],
  poNumber: "",
  notes: "",
  isMultiUser: false,
  isAssignToAllUsers: false,
  selectedUsers: [],
  selectedUsersId: [],
	division: "",
	isEditMode: false,
};

const mutations = {
  SET_TEMPLATE_NAME: (state, payload) => {
    state.templateName = payload;
  },
  SET_BILL_TOS: (state, payload) => {
    state.billTos = payload;
  },
  SET_CONSIGNEES: (state, payload) => {
    state.consignees = payload;
  },
  SET_COMMODITIES: (state, payload) => {
    state.commodities = payload;
  },
  SET_SHIPPERS: (state, payload) => {
    state.shippers = payload;
  },
  SET_SUPPLIERS: (state, payload) => {
    state.suppliers = payload;
  },
  SET_ACCOUNT_OFS: (state, payload) => {
    state.accountOfs = payload;
  },
  SET_SELECTED_CONSIGNEE: (state, payload) => {
    state.selectedConsignee = payload;
  },
  SET_SELECTED_SHIPPER: (state, payload) => {
    state.selectedShipper = payload;
  },
  SET_SELECTED_SUPPLIER: (state, payload) => {
    state.selectedSupplier = payload;
  },
  SET_SELECTED_ACCOUNT_OF: (state, payload) => {
    state.selectedAccountOf = payload;
  },
  SET_SELECTED_COMMODITY: (state, payload) => {
    state.selectedCommodity = payload;
  },
  SET_SELECTED_SPLASH_COMMODITY:(state, payload) => {
    state.selectedSplashCommodity = payload;
  },
  SET_SELECTED_QUANTITY: (state, payload) => {
    state.selectedQuantity = payload;
  },
  SET_SELECTED_AMOUNTUNITOFMEASURE: (state, payload) => {
    state.selectedAmountUnitOfMeasure = payload;
  },
  ADD_PRODUCT: (state, payload) => {
    if (payload.productIndex != null) {
      state.selectedProducts[payload.productIndex] = payload.product;
    } else {
      state.selectedProducts.push(payload.product);
    }
  },
  DELETE_PRODUCT: (state, payload) => {
    state.selectedProducts.splice(payload, 1);
  },
  SET_PO_NUMBER: (state, payload) => {
    state.poNumber = payload;
  },
  SET_NOTES: (state, payload) => {
    state.notes = payload;
  },
  SET_SELECTED_ORDER_TEMPLATE_USER_ASSIGNMENT: (state, payload) => {
    state.selectedUsers = payload;
  },
  SET_IS_MULTI_USER_TEMPLATE: (state, payload) => {
    state.isMultiUser = payload;
  },
  SET_IS_ASSIGN_TO_ALL_USERS: (state, payload) => {
    state.isAssignToAllUsers = payload
  },
  CLEAR_SELECTED_ORDER_TEMPLATE_USER_ASSIGNMENT: state => {
    state.selectedUsers = [];
  },
  CLEAR_PRODUCT_DETAILS: state => {
    state.selectedShipper = "";
    state.selectedSupplier = "";
    state.selectedAccountOf = "";
    state.selectedCommodity = "";
    state.selectedQuantity = "";
		state.isEditMode = false;
  },
  CLEAR_SELECTED_PRODUCTS: state => {
    state.selectedProducts = [];
  },
  CLEAR_ORDER_TEMPLATE_DATA: state => {
    state.templateName = "";
    state.shippers = [];
    state.suppliers = [];
    state.accountOfs = [];
    state.selectedConsignee = "";
    state.selectedShipper = "";
    state.selectedSupplier = "";
    state.selectedAccountOf = "";
    state.selectedCommodity = "";
    state.selectedQuantity = "";
    state.selectedProducts = [];
    state.poNumber = "";
    state.notes = "";
    state.selectedAmountUnitOfMeasure = "";
    state.amountUnitOfMeasure = state.selectedAmountUnitOfMeasure;
    state.isMultiUser = false;
    state.isAssignToAllUsers = false;
    state.selectedUsers = [];
    state.selectedUsersId = [];
  },
  SET_SELECTED_ORDER_TEMPLATE_DATA: (state, payload) => {
    state.templateId = payload.templateId;
    state.templateName = payload.templateName;
    state.shippers = [];
    state.suppliers = [];
    state.accountOfs = [];
    state.selectedBillTo = payload.billTo;
    //state.selectedConsignee = payload.details[0].deliverySite;
    state.selectedProducts = payload.details;
    state.poNumber = payload.po;
    state.notes = payload.notes;
    state.selectedAmountUnitOfMeasure = ((payload.selectedAmountUnitOfMeasure == undefined || payload.selectedAmountUnitOfMeasure == '') ? 'GAL' : payload.selectedAmountUnitOfMeasure);
    state.amountUnitOfMeasure = state.selectedAmountUnitOfMeasure;
    state.isAssignToAllUsers = payload.isAssignToAllUsers;
    state.isMultiUser = payload.isMultiUser;
    state.selectedUsersId = payload.selectedUsers;
  },
	SET_DIVISION: (state, division) => {
		state.division = division ? division : "FDG";
	},
	SET_IS_EDIT_MODE: (state) => {
    state.isEditMode = true
  },
}

const actions = {
  setTemplateName(context, name) {
    context.commit("SET_TEMPLATE_NAME", name);
  },

  async getAllBillTos(context) {
		try{
			const { data: { results: results, totalRecords: totalRecords } } =
				await axios.get(`${urls.RelationshipsBaseUrl}/billtos`);

			if(totalRecords > 0){
				context.commit("SET_BILL_TOS", results);
			}
		}catch(err){
			throw err;
		}
  },

  async getConsignees(context) {
		try{
			const {data: { results: results, totalRecords: totalRecords } } = await axios.get(
				`${urls.RelationshipsBaseUrl}/consignees`, {
			});
			
			if(totalRecords > 0){
				const consigneeData = results.map(consignee => ({
					id: consignee.id ? 
							consignee.id : 
							defaultValue,

						name: consignee.name ? 
							consignee.name.toLowerCase()
								.split(' ')
								.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
								.join(' ') :
							defaultValue,

						billTo: consignee.billTo ?
							consignee.billTo :
							defaultValue,

          address: consignee.address ?
            consignee.address :
            defaultValue,

					address2: consignee.address2 ?
            consignee.address2 :
            null,
          
						city: consignee.city ?
							consignee.city :
							defaultValue,

						state: consignee.state ? 
							consignee.state :
							defaultValue,

						division: consignee.division ?
							consignee.division :
							defaultValue,
            
            postalCode: consignee.zipCode ?
              consignee.zipCode : 
              defaultValue
				}));

				let division =
					consigneeData[0].division !== defaultValue ?
					consigneeData[0].division :
					"FDG";

				context.commit("SET_DIVISION", division);
				context.commit("SET_CONSIGNEES", consigneeData);
			}
			return true;
		}catch(err){
			return false;
		}
  },

  async getShippers(context, requestData) {
		try{
			const {data: { results: results, totalRecords: totalRecords } } = await axios.get(
				`${urls.RelationshipsBaseUrl}/Shippers`, {
					params: {
						billTo: requestData.billTo,
						consignee: requestData.consignee,
						division: requestData.division}
				});

			if(totalRecords > 0){
				let shipperData = results.map(shipper => ({
					id: shipper.id ?
							shipper.id :
							defaultValue,

					name: shipper.name ?
							shipper.name.toLowerCase()
								.split(' ')
								.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
								.join(' ') :
							defaultValue,
					
					zipCode: shipper.zipCode ?
							shipper.zipCode :
							defaultValue,
				}));
				context.commit("SET_SHIPPERS", shipperData);
			}
			return true;
		}catch(err){
			return false;
		}
  },
	
  async getSuppliers(context, requestData) {
		try{
			const {data: { results: results, totalRecords: totalRecords } } = await axios.get(
				`${urls.RelationshipsBaseUrl}/Suppliers`, {
					params: {
						division: requestData.division,
						billTo: requestData.billTo,
						consignee: requestData.consignee,
						shipper: requestData.shipper
					}
				});
			
			if(totalRecords > 0){
				let supplierData = results.map(supplier => ({
					id: supplier.id ?
							supplier.id :
							defaultValue,

						name: supplier.name ?
							supplier.name.toLowerCase()
								.split(' ')
								.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
								.join(' ') :
							defaultValue
				}));
				context.commit("SET_SUPPLIERS", supplierData);
			}
		// eslint-disable-next-line
		}catch(err){
			throw err;
		}
  },

  async getAccountOfs(context, requestData) {
		try{
			const {data: { results: results, totalRecords: totalRecords } } =	await axios.get(
				`${urls.RelationshipsBaseUrl}/AccountOfs`, {
					params: {
						division: requestData.division,
						billTo: requestData.billTo,
						consignee: requestData.consignee,
						shipper: requestData.shipper,
						supplier: requestData.supplier
					}
				});

			if(totalRecords > 0){
				let accountOfData = results.map(accountOf => ({
					id: accountOf.id ? 
						 accountOf.id :
						 defaultValue,

						name: accountOf.name ?
							accountOf.name.toLowerCase()
								.split(' ')
								.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
								.join(' ') :
								defaultValue
				}));
				context.commit("SET_ACCOUNT_OFS", accountOfData);
			}
		// eslint-disable-next-line
		}catch(err){
			throw err;
		}
  },

  async getCommodities(context, requestData) {
		try{
			let parameters = {
				billTo: requestData.billTo,
				consignee: requestData.consignee,
				division: requestData.division
			}

			if (requestData.isAdvancedView) {
				parameters["shipper"] = requestData.shipper;
				parameters["supplier"] = requestData.supplier;
				parameters["accountOf"] = requestData.accountOf;
			}

			const {data: { results: results, totalRecords: totalRecords } } = await axios.get(
				`${urls.RelationshipsBaseUrl}/commodities`, {	params: parameters }
			);
			
			if(totalRecords > 0){
				let commoditiesData = results.map(commodity => ({
						id: commodity.commodityCode ?
							commodity.commodityCode :
							defaultValue,

						name: commodity.name ?
							commodity.name.toLowerCase()
								.split(' ')
								.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
								.join(' ') :
							defaultValue,
				}));
				context.commit("SET_COMMODITIES", commoditiesData);
			}
			return true;
		// eslint-disable-next-line
		}catch(err){
			throw err;
		}
  },

	// eslint-disable-next-line
  async saveOrderTemplate(context, requestData) {
    await axios.post(
      `${urls.LoadTenderTemplateUrl}`,
      requestData.orderTemplate
    );
  },

	// eslint-disable-next-line
  async updateOrderTemplate(context, requestData) {
    await axios.put(
      `${urls.LoadTenderTemplateUrl}/${requestData.uId}`,
      requestData.orderTemplate, {}
    );
  },

  setIsMultiUserTemplate(context, isMultiUser) {
    context.commit("SET_IS_MULTI_USER_TEMPLATE", isMultiUser);
  },

  setIsAssignToAllUsers(context, isAssignToAllUsers) {
    context.commit("SET_IS_ASSIGN_TO_ALL_USERS", isAssignToAllUsers);
  },

  setSelectedConsignee(context, siteId) {
    context.commit("SET_SELECTED_CONSIGNEE", siteId);
  },

  setSelectedShipper(context, shipper) {
    context.commit("SET_SELECTED_SHIPPER", shipper);
  },

  setSelectedSupplier(context, supplier) {
    context.commit("SET_SELECTED_SUPPLIER", supplier);
  },

  setSelectedAccountOf(context, accountOf) {
    context.commit("SET_SELECTED_ACCOUNT_OF", accountOf);
  },

  setSelectedCommodity(context, commodity) {
    context.commit("SET_SELECTED_COMMODITY", commodity);
  },

  setSelectedSplashCommodity(context, commodity){
    context.commit("SET_SELECTED_SPLASH_COMMODITY", commodity)
  },

  setSelectedQuantity(context, commodity) {
    context.commit("SET_SELECTED_QUANTITY", commodity);
  },

  setSelectedAmountUnitOfMeasure(context, commodity) {
    context.commit("SET_SELECTED_AMOUNTUNITOFMEASURE", commodity);
  },

  setPoNumber(context, poNumber) {
    context.commit("SET_PO_NUMBER", poNumber);
  },

  setNotes(context, notes) {
    context.commit("SET_NOTES", notes);
  },

  setSelectedOrderTemplateUserAssignment(context, selectedUsers ) {
    context.commit("SET_SELECTED_ORDER_TEMPLATE_USER_ASSIGNMENT", selectedUsers);
  },

  clearSelectedOrderTemplateUserAssignment(context) {
    context.commit("CLEAR_SELECTED_ORDER_TEMPLATE_USER_ASSIGNMENT");
  },
	
  clearProductDetails(context) {
    context.commit("CLEAR_PRODUCT_DETAILS");
  },

  addProduct(context, objProduct) {
    context.commit("ADD_PRODUCT", objProduct);
  },

  deleteProduct(context, objProduct) {
    context.commit("DELETE_PRODUCT", objProduct);
  },

  clearSelectedProducts(context) {
    context.commit("CLEAR_SELECTED_PRODUCTS");
  },

  clearOrderTemplateData(context) {
    context.commit("CLEAR_ORDER_TEMPLATE_DATA");
  },

  async setSelectedOrderTemplateData(context, requestData) {
    try {
      context.commit("CLEAR_ORDER_TEMPLATE_DATA");
      var res = await axios.get(`${urls.LoadTenderTemplateUrl}/${requestData.id}`, {});
      if (res.status == 200) {
        context.commit("SET_SELECTED_ORDER_TEMPLATE_DATA", res.data);
        return true;
      }
      return false;
			// eslint-disable-next-line
    } catch (error) {
      return false;
    }
  },

	setIsEditMode(context, value) {
    context.commit("SET_IS_EDIT_MODE", value)
  }
}

const getters = {
  filteredConsignees: state => {
    if (state.selectedProducts.length > 0) {
      return state.consignees.filter(consignee => consignee.billTo == state.selectedProducts[0].deliverySite.billTo);
    }
    return state.consignees;
  },

  getFilteredSelectedUsersUIds: (state) => {
    return state.selectedUsers.map(e => e.id);
  },

	getDivisionForTemplate: state => {
		return state.division;
	},

	getIsEditMode: state => {
		return state.isEditMode;
	}
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
