import axios from "axios";
import urls from "@/shared/urls";


var baseUrl = urls.RelationshipsBaseUrl;
var endpoint = "/userrelationshipfilters";

export default {
  async get(data) {
 
    const response = await axios.get(baseUrl + endpoint, {
      headers: {
        Authorization: `Bearer ${data.jwtToken}`
      },
      params: {
        //customerId: data.customerId,
        userId: data.userId,
        skip: data.skip,
        take: data.take
      }
    });

    return response;
  },
  async post(requestData) { 
    const user = await axios.post(baseUrl + endpoint,
      requestData.postData, {
        headers: {
          
        }
      }
    );

    return user;

  },
  async delete(requestData) {
    await axios.delete(baseUrl + endpoint, {
        headers: {
          
        },
        params: {
          id: requestData.id,
        }
      });
  },
};
