const namespaced = true;

const defaultEntity = () => {
  return {
    availableRecords: [],
    totalRecords: 0
  };
};

const state = {
  divisions: {
    availableRecords: [],
    totalRecords: 0,
    isSpg: false
  },
  selectedDivisions: [],
  isSpg: false,
  billTos: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedBillTos: [],

  consignees: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedConsignees: [],

  shippers: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedShippers: [],

  suppliers: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedSuppliers: [],

  accountOfs: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedAccountOfs: [],

  commodities: {
    availableRecords: [],
    totalRecords: 0
  },
  selectedCommodities: [],

  cities: [],
  selectedCities: [],
  selectedShipperCities: [],
  selectedSupplierCities: [],
  states: [],
  selectedStates: [],
  selectedShipperStates: [],
  selectedSupplierStates: [],

  terminals: [],
  selectedTerminals: [],

  carriers: [],
  selectedCarriers: [],

  subsidiaries: [],
  selectedSubsidiaries: [],

  commodityClasses: [],
  selectedCommodityClasses: []
};

const mutations = {
  
  MUTATE_SET_DIVISIONS: (state, payload) => {
    state.divisions.availableRecords = payload.availableRecords;
  },
  MUTATE_SET_SELECTED_DIVISIONS: (state, payload) => {
    state.selectedDivisions = payload;
    if (state.selectedDivisions.length > 0) {
      if (state.selectedDivisions[0].source === "SPG") {
        state.isSpg = true;
      }
    } else {
      state.isSpg = false;
    }
  },

  MUTATE_SET_BILLTOS: (state, payload) => {
    state.billTos = payload;
  },
  MUTATE_SET_SELECTED_BILLTOS: (state, payload) => {
    state.selectedBillTos = payload;
  },
  MUTATE_SET_CONSIGNEES: (state, payload) => {
    state.consignees = payload;
  },
  MUTATE_SET_SELECTED_CONSIGNEES: (state, payload) => {
    state.selectedConsignees = payload;
  },
  MUTATE_SET_SHIPPERS: (state, payload) => {
    state.shippers = payload;
  },
  MUTATE_SET_SELECTED_SHIPPERS: (state, payload) => {
    state.selectedShippers = payload;
  },
  MUTATE_SET_SUPPLIERS: (state, payload) => {
    state.suppliers = payload;
  },
  MUTATE_SET_SELECTED_SUPPLIERS: (state, payload) => {
    state.selectedSuppliers = payload;
  },

  MUTATE_SET_ACCOUNTOFS: (state, payload) => {
    state.accountOfs = payload;
  },
  MUTATE_SET_SELECTED_ACCOUNTOFS: (state, payload) => {
    state.selectedAccountOfs = payload;
  },
  MUTATE_SET_COMMODITIES: (state, payload) => {
    state.commodities = payload;
  },
  MUTATE_SET_SELECTED_COMMODITIES: (state, payload) => {
    state.selectedCommodities = payload;
  },

  MUTATE_SET_CITIES: (state, payload) => {
    state.cities = payload;
  },
  MUTATE_SET_SELECTED_CITIES: (state, payload) => {
    state.selectedCities = payload;
  },
  MUTATE_SET_SELECTED_SHIPPER_CITIES: (state, payload) => {
    state.selectedShipperCities = payload;
  },
  MUTATE_SET_SELECTED_SUPPLIER_CITIES: (state, payload) => {
    state.selectedSupplierCities = payload;
  },
  MUTATE_SET_STATES: (state, payload) => {
    state.states = payload;
  },
  MUTATE_SET_SELECTED_STATES: (state, payload) => {
    state.selectedStates = payload;
  },
  MUTATE_SET_SELECTED_SHIPPER_STATES: (state, payload) => {
    state.selectedShipperStates = payload;
  },
  MUTATE_SET_SELECTED_SUPPLIER_STATES: (state, payload) => {
    state.selectedSupplierStates = payload;
  },

  MUTATE_SET_TERMINALS: (state, payload) => {
    state.terminals = payload;
  },
  MUTATE_SET_SELECTED_TERMINALS: (state, payload) => {
    state.selectedTerminals = payload;
  },

  MUTATE_SET_CARRIERS: (state, payload) => {
    state.carriers = payload;
  },
  MUTATE_SET_SELECTED_CARRIERS: (state, payload) => {
    state.selectedCarriers = payload;
  },

  MUTATE_SET_SUBSIDIARIES: (state, payload) => {
    state.subsidiaries = payload;
  },
  MUTATE_SET_SELECTED_SUBSIDIARIES: (state, payload) => {
    state.selectedSubsidiaries = payload;
  },

  MUTATE_SET_COMMODITYCLASSES: (state, payload) => {
    state.commodityClasses = payload;
  },
  MUTATE_SET_SELECTED_COMMODITYCLASSES: (state, payload) => {
    state.selectedCommodityClasses = payload;
  }
};

const actions = {
  setDivisions({ commit }, payload) {
    commit("MUTATE_SET_DIVISIONS", payload);
  },
  setSelectedDivisions({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_DIVISIONS", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_BILLTOS", []);
    commit("MUTATE_SET_SELECTED_CONSIGNEES", []);
    commit("MUTATE_SET_SELECTED_SHIPPERS", []);
    commit("MUTATE_SET_SELECTED_SUPPLIERS", []);
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },
  setBillTos({ commit }, payload) {
    commit("MUTATE_SET_BILLTOS", payload);
  },
  setSelectedBillTos({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_BILLTOS", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_CONSIGNEES", []);
    commit("MUTATE_SET_SELECTED_SHIPPERS", []);
    commit("MUTATE_SET_SELECTED_SUPPLIERS", []);
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },
  setConsignees({ commit }, payload) {
    commit("MUTATE_SET_CONSIGNEES", payload);
  },
  setSelectedConsignees({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_CONSIGNEES", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_SHIPPERS", []);
    commit("MUTATE_SET_SELECTED_SUPPLIERS", []);
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },
  setShippers({ commit }, payload) {
    commit("MUTATE_SET_SHIPPERS", payload);
  },
  setSelectedShippers({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_SHIPPERS", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_SUPPLIERS", []);
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },

  setSuppliers({ commit }, payload) {
    commit("MUTATE_SET_SUPPLIERS", payload);
  },
  setSelectedSuppliers({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_SUPPLIERS", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },

  setAccountOfs({ commit }, payload) {
    commit("MUTATE_SET_ACCOUNTOFS", payload);
  },
  setSelectedAccountOfs({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", payload);

    //if we change this, that should change everything below it since the data will change.
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
  },

  setCommodities({ commit }, payload) {
    commit("MUTATE_SET_COMMODITIES", payload);
  },
  setSelectedCommodities({ commit }, payload) {
    commit("MUTATE_SET_SELECTED_COMMODITIES", payload);
  },

  setCities(context, payload) {
    context.commit("MUTATE_SET_CITIES", payload);
  },
  setSelectedCities(context, payload) {
    context.commit("MUTATE_SET_SELECTED_CITIES", payload);
  },
  setSelectedShipperCities(context, payload) {
    context.commit("MUTATE_SET_SELECTED_SHIPPER_CITIES", payload);
  },
  setSelectedSupplierCities(context, payload) {
    context.commit("MUTATE_SET_SELECTED_SUPPLIER_CITIES", payload);
  },


  setStates(context, payload) {
    context.commit("MUTATE_SET_STATES", payload);
  },
  setSelectedStates(context, payload) {
    context.commit("MUTATE_SET_SELECTED_STATES", payload);
  },
  setSelectedShipperStates(context, payload) {
    context.commit("MUTATE_SET_SELECTED_SHIPPER_STATES", payload);
  },
  setSelectedSupplierStates(context, payload) {
    context.commit("MUTATE_SET_SELECTED_SUPPLIER_STATES", payload);
  },

  setTerminals(context, payload) {
    context.commit("MUTATE_SET_TERMINALS", payload);
  },
  setSelectedTerminals(context, payload) {
    context.commit("MUTATE_SET_SELECTED_TERMINALS", payload);
  },

  setCarriers(context, payload) {
    context.commit("MUTATE_SET_CARRIERS", payload);
  },
  setSelectedCarriers(context, payload) {
    context.commit("MUTATE_SET_SELECTED_CARRIERS", payload);
  },

  setSubsidiaries(context, payload) {
    context.commit("MUTATE_SET_SUBSIDIARIES", payload);
  },
  setSelectedSubsidiaries(context, payload) {
    context.commit("MUTATE_SET_SELECTED_SUBSIDIARIES", payload);
  },

  setCommodityClasses(context, payload) {
    context.commit("MUTATE_SET_COMMODITYCLASSES", payload);
  },
  setSelectedCommodityClasses(context, payload) {
    context.commit("MUTATE_SET_SELECTED_COMMODITYCLASSES", payload);
  },

  clearState({ commit }, payload) {
    if (payload == 1) {
      commit("MUTATE_SET_DIVISIONS", defaultEntity());
      commit("MUTATE_SET_BILLTOS", defaultEntity());
      commit("MUTATE_SET_CONSIGNEES", defaultEntity());
      commit("MUTATE_SET_SHIPPERS", defaultEntity());
      commit("MUTATE_SET_SUPPLIERS", defaultEntity());
      commit("MUTATE_SET_ACCOUNTOFS", defaultEntity());
      commit("MUTATE_SET_COMMODITIES", defaultEntity());
      commit("MUTATE_SET_COMMODITYCLASSES", defaultEntity());
    }

    commit("MUTATE_SET_SELECTED_DIVISIONS", []);
    commit("MUTATE_SET_SELECTED_BILLTOS", []);
    commit("MUTATE_SET_SELECTED_CONSIGNEES", []);
    commit("MUTATE_SET_SELECTED_SHIPPERS", []);
    commit("MUTATE_SET_SELECTED_SUPPLIERS", []);
    commit("MUTATE_SET_SELECTED_ACCOUNTOFS", []);
    commit("MUTATE_SET_SELECTED_COMMODITIES", []);
    commit("MUTATE_SET_SELECTED_COMMODITYCLASSES", []);
    commit("MUTATE_SET_SELECTED_SUBSIDIARIES", []);
  }
};

const getters = {
  getDivisions: state => {
    return state.divisions;
  },
  getSelectedDivisions: state => {
    try {
      var division = JSON.parse(JSON.stringify(state.selectedDivisions.source));
      return [state.selectedDivisions];
    } catch {
      return state.selectedDivisions;
    }
  },
  getIsSpg: state => {
    return state.isSpg;
  },
  getBillTos: state => {
    return state.billTos;
  },
  getSelectedBillTos: state => {
    return state.selectedBillTos;
  },
  getConsignees: state => {
    return state.consignees;
  },
  getSelectedConsignees: state => {
    return state.selectedConsignees;
  },
  getShippers: state => {
    return state.shippers;
  },
  getSelectedShippers: state => {
    return state.selectedShippers;
  },
  getSuppliers: state => {
    return state.suppliers;
  },
  getSelectedSuppliers: state => {
    return state.selectedSuppliers;
  },
  getAccountOfs: state => {
    return state.accountOfs;
  },
  getSelectedAccountOfs: state => {
    return state.selectedAccountOfs;
  },
  getCommodities: state => {
    return state.commodities;
  },
  getSelectedCommodities: state => {
    return state.selectedCommodities;
  },
  getCommodityClasses: state => {
    return state.commodityClasses;
  },
  getSelectedCommodityClasses: state => {
    return state.selectedCommodityClasses;
  },
  getSubsidiaries: state => {
    return state.subsidiaries;
  },
  getSelectedSubsidiaries: state => {
    return state.selectedSubsidiaries;
  }
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
